import { Auth as FbAuth, User as FbUser } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import firebaseConfig from './firebaseConfig'

import { Session } from '@lib/brz-core-lib-sdk-ts/utils'
import {
  UserToken,
  UserMetadata,
  UserSession,
  ProviderId,
} from '@lib/brz-core-lib-type-ts/user'

export const app = firebase.initializeApp(firebaseConfig)

export const { GoogleAuthProvider, PhoneAuthProvider } = firebase.auth

export type Auth = FbAuth
export type User = FbUser

export const auth = firebase.auth() as unknown as Auth

export class SessionImpl implements Session {
  auth: FbAuth

  constructor() {
    this.auth = auth
  }
  async getToken(forceRefresh?: boolean): Promise<UserToken> {
    const token = await this.auth?.currentUser?.getIdTokenResult(forceRefresh)
    return {
      ...token,
    }
  }
  getMetadata(): Promise<UserMetadata> {
    const metadata = this.auth?.currentUser?.metadata
    return Promise.resolve({
      ...metadata,
    })
  }
  getSession(): Promise<UserSession> {
    const session = this.auth?.currentUser
    return Promise.resolve(
      session
        ? {
            ...session,
            providerId: session?.providerId as ProviderId,
            name: session?.displayName,
            phone: session?.phoneNumber,
            images: session?.photoURL ? [session.photoURL] : undefined,
          }
        : undefined,
    )
  }
}
