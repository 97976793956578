const deleteUndefined = <T extends object>(obj: T): T => {
  if (obj) {
    const record = obj as Record<string | number, unknown>
    Object.keys(record).forEach(key => {
      if (record[key] && typeof record[key] === 'object') {
        deleteUndefined(record[key] as object)
      } else if (typeof record[key] === 'undefined') {
        delete record[key]
      }
    })
  }
  return obj
}
export default deleteUndefined
