import React, { ReactNode } from 'react'
import { Box, VStack, Grid, HStack, Button } from '@chakra-ui/react'
import { ColorModeSwitcher } from '../../ColorModeSwitcher'
import { navigate, useLocation } from '@reach/router'

export const Layout = ({ children }: { children?: ReactNode }) => {
  const location = useLocation()
  const is = (path: string) => !!location?.pathname?.endsWith(path)

  return (
    <Grid p={3} overflowX="auto">
      <ColorModeSwitcher justifySelf="flex-end" />
      <VStack spacing={8} align="flex-start">
        <HStack spacing={4}>
          <Button
            onClick={() => {
              navigate('/admin/orders').finally(() => {})
            }}
            variant="solid"
            colorScheme={is('/orders') ? 'pink' : 'gray'}>
            Orders
          </Button>
          <Button
            onClick={() => {
              navigate('/admin/sessions').finally(() => {})
            }}
            variant="solid"
            colorScheme={is('/sessions') ? 'pink' : 'gray'}>
            Sessions
          </Button>
          <Button
            onClick={() => {
              navigate('/admin/parties').finally(() => {})
            }}
            variant="solid"
            colorScheme={is('/parties') ? 'pink' : 'gray'}>
            Parties
          </Button>
          <Button
            onClick={() => {
              navigate('/admin/messages').finally(() => {})
            }}
            variant="solid"
            colorScheme={is('/messages') ? 'pink' : 'gray'}>
            Messages
          </Button>
          <Button
            onClick={() => {
              navigate('/admin/payments').finally(() => {})
            }}
            variant="solid"
            colorScheme={is('/payments') ? 'pink' : 'gray'}>
            Payments
          </Button>
        </HStack>
      </VStack>
      {children}
    </Grid>
  )
}
