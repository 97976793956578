import React, { ReactNode } from 'react'
import { RouteComponentProps } from '@reach/router'
import { PartyClient } from '@lib/brz-core-lib-sdk-ts/party'
import { DatabaseImpl } from '../../firebase/modular'
import { SessionImpl } from '../../firebase/compat'
import config from '../../config'
import { Text } from '@chakra-ui/react'
import { EnvType } from '@lib/brz-core-lib-type-ts/utils'
import { logger } from '@lib/brz-core-lib-sdk-ts/utils'

interface Props extends RouteComponentProps {
  merchant?: string
  children?: ReactNode
}

logger.setApp({ name: 'brz-partner-app-web-react' })
logger.addOptions({
  console: { threshold: 'warn' },
  sentry: { threshold: 'warn' },
})

const session = new SessionImpl()
const db = new DatabaseImpl(logger)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const partyClient = new PartyClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  partyApi: config('PARTY_API'),
})

export const MerchantHomePage = ({ merchant, children }: Props) => (
  <>
    <Text>{merchant}</Text>
    {children}
  </>
)
