import { useState } from 'react'
import {
  Flex,
  Image,
  ButtonGroup,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Box,
  AvatarGroup,
  Menu,
  Avatar,
  MenuButton,
  MenuList,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { MdNotifications, MdSettings, MdClose, MdMenu } from 'react-icons/md'
import { NavigateFn } from '@reach/router'

import { Party } from '@lib/brz-core-lib-type-ts/party'
import { User } from '@lib/brz-core-lib-type-ts/user'
import { image } from 'src/lib/brz-core-lib-type-ts/utils'

interface HeaderProps {
  navigate: NavigateFn
  path: string
  party?: Party
  user?: User
}

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box
      display={{ base: 'block', md: 'none' }}
      onClick={toggle}
      cursor="pointer">
      {isOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
    </Box>
  )
}

export const Header = ({ navigate, path, party, user }: HeaderProps) => {
  const is = (page: string) => path.endsWith(page)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Flex
      bg="white"
      as="header"
      w="100%"
      h="20"
      mx="auto"
      align="center"
      justifyContent="center"
      boxShadow="sm"
      px="6">
      <HStack maxWidth={1480} w="100%" flex={1} justify="space-between">
        <Image
          src="/brz-logo_300x180.png"
          alt="Beach Runnerz"
          htmlHeight={60}
          htmlWidth={100}
        />

        {/* <HStack display={{ base: 'none', md: 'flex' }}>
          <ButtonGroup variant="solid">
            <Button
              onClick={() => {
                navigate && navigate('../orders')
              }}
              bg={is('orders') ? undefined : 'white'}>
              Orders
            </Button>
            <Button
              disabled={true}
              onClick={() => {
                navigate && navigate('../products')
              }}
              bg={is('products') ? undefined : 'white'}>
              Products
            </Button>
            <Button
              disabled={true}
              onClick={() => {
                navigate && navigate('../history')
              }}
              bg={is('history') ? undefined : 'white'}>
              History
            </Button>
          </ButtonGroup>
        </HStack> */}

        <HStack
          spacing="4"
          align="center"
          display={{ base: 'none', md: 'flex' }}>
          {/* <Tooltip label="Settings">
            <IconButton
              aria-label="Settings"
              icon={<MdSettings size={24} />}
              rounded="100"
              bg="white"
              disabled={true}
            />
          </Tooltip> */}

          {/* <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Notifications"
              icon={<MdNotifications size={24} />}
              rounded="100"
              bg="white"
              disabled={true}
            />
            <MenuList>
              <MenuItem></MenuItem>
            </MenuList>
          </Menu>
          <AvatarGroup>
            <Avatar name={party?.name} src={image(party?.images)} />
            <Avatar name={user?.name} src={'' /*image(user?.images)* /} />
          </AvatarGroup> */}
        </HStack>
      </HStack>

      <MenuToggle isOpen={isOpen} toggle={toggle} />

      <VStack
        display={{ base: isOpen ? 'flex' : 'none', md: 'none' }}
        spacing={2}
        position="absolute"
        zIndex={999}
        top={20}
        direction="column"
        left={0}
        bottom={0}
        py="4"
        w="100%"
        bg="blue.300"
        alignItems="center">
        <Button
          color="white"
          bg="transparent"
          onClick={() => {
            navigate && navigate('../orders')
          }}>
          Orders
        </Button>

        <Button
          disabled
          color="white"
          bg="transparent"
          onClick={() => {
            navigate && navigate('../products')
          }}>
          Products
        </Button>

        <Button
          disabled={true}
          color="white"
          bg="transparent"
          onClick={() => {
            navigate && navigate('../history')
          }}>
          History
        </Button>

        <Button
          disabled={true}
          color="white"
          bg="transparent"
          onClick={() => {
            navigate && navigate('../history')
          }}>
          Notifications
        </Button>

        <Button
          disabled={true}
          color="white"
          bg="transparent"
          onClick={() => {
            navigate && navigate('../history')
          }}>
          Settings
        </Button>
      </VStack>
    </Flex>
  )
}
