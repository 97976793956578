import { ChakraProvider } from '@chakra-ui/react'
import { Router } from '@reach/router'
import { init } from './sentry'
import { HomeScreen } from './pages'
import {
  AdminOrdersScreen,
  AdminSessionsScreen,
  AdminPartiesScreen,
  AdminMessagesScreen,
  AdminPaymentsScreen,
} from './pages/admin'
import { MerchantOrdersPage } from './pages/merchants'
import { theme } from './styles/theme'

init()

export const App = () => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Router>
        <MerchantOrdersPage path="/merchants/:merchant/orders" />
        <AdminOrdersScreen path="/admin/orders" />
        <AdminSessionsScreen path="/admin/sessions" />
        <AdminPartiesScreen path="/admin/parties" />
        <AdminMessagesScreen path="/admin/messages" />
        <AdminPaymentsScreen path="/admin/payments" />
        <HomeScreen path="/" />
      </Router>
    </ChakraProvider>
  )
}
