import {
  MessageService,
  MessageCommand,
  commandReducer,
  EventCallback,
  MessageEvent,
} from '@lib/brz-core-lib-type-ts/message'
import { OrderService } from '@lib/brz-core-lib-type-ts/order'
import { Party, PartyService } from '@lib/brz-core-lib-type-ts/party'
import { EnvType, last } from '@lib/brz-core-lib-type-ts/utils'
import { Id } from '@lib/brz-core-lib-type-ts/utils/primitives'
import { timestamp } from '@lib/brz-core-lib-type-ts/utils/timestamp'
import { OrderClient } from '../order'
import { PartyClient } from '../party'
import { Database, Logger, Session } from '../utils'

export class MessageClient implements MessageService {
  prefix: EnvType
  logger: Logger
  session: Session
  db: Database
  orderService: OrderService
  partyService: PartyService

  constructor({
    prefix,
    session,
    logger,
    db,
    partyApi,
    productApi,
  }: {
    prefix: EnvType
    logger: Logger
    session: Session
    db: Database
    partyApi: string
    productApi: string
  }) {
    this.prefix = prefix
    this.logger = logger
    this.session = session
    this.db = db
    this.orderService = new OrderClient({
      prefix,
      session,
      logger,
      db,
      partyApi,
      productApi,
      messageService: this,
    })
    this.partyService = new PartyClient({
      prefix,
      session,
      db,
      partyApi,
      logger,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ld(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.d(message, {
      context,
      error,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  li(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.i(message, {
      context,
      error,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  le(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.e(message, {
      context,
      error,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lw(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.w(message, {
      context,
      error,
    })
  }

  getRepos(path: string) {
    const result = [
      this.db.ref([this.prefix, path].filter(item => item).join('/')),
    ]

    if (!this.prefix) {
      this.lw('Prefix not provided!', {})
      throw new Error('Prefix not provided!')
    }
    // if (this.prefix === 'prd') {
    //   result.push(this.db.ref(path))
    // }

    return result
  }

  async dispatch(id: Id<'party'>, commands: MessageCommand[]) {
    const LOG_METHOD = 'MessageClient.dispatch'
    const LOG_CONTEXT = { partyId: id }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const commandsRepository = this.getRepos(`messages/commands/${id}`)
      const refRepository = this.getRepos(`messages/ref/${id}`)

      const user = (await this.session?.getSession())?.uid

      commands = commands.map(command => ({
        ...command,
        metadata: {
          ...command.metadata,
          createdAt: command.metadata?.createdAt || timestamp(),
          createdBy: command.metadata?.createdAt || user || '', //TODO: handle non-auth
          timestamp: this.db.serverTimestamp() as unknown as number,
        },
      }))

      const ids = commands
        .map(command => {
          const message = command.payload.message

          return [
            ...(message.context.guest ? [message.context.guest] : []),
            ...(message.context.runner ? [message.context.runner] : []),
            ...(message.context.merchants ?? []),
          ]
        })
        .flat()

      const parties = await this.partyService.getParties(ids.map((eachParty: Party) => eachParty.id))

      const eventsMap = commandReducer(commands, parties)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updates = {} as any
      updates['version'] = this.db.serverTimestamp()

      await Promise.all([
        commandsRepository.map(repository => repository.push(commands)),
        refRepository.map(repository => repository.update(updates)),
        ...Array.from(eventsMap.entries()).map(([target, events]) =>
          this.getRepos(`messages/events/${target}`).map(repository =>
            repository.push(events),
          ),
        ),
      ])

      commands.forEach(command => {
        const context = command.payload?.message?.context
        if (
          context?.action === 'runnerAcceptOrder' &&
          context?.order &&
          context?.runner
        ) {
          void this.orderService.dispatch(context.order, [
            {
              type: 'acceptByRunner',
              payload: {
                order: { id: context.order, runner: { id: context.runner } },
              },
            },
          ])
        }
      })
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
    } finally {
      this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  subsMap = new Map<string, () => void>()
  subsCount = 0

  subscribeMessages(
    id: Id<'party'>,
    timestamp: number,
    callback: EventCallback,
  ): string {
    const LOG_METHOD = 'MessageClient.subscribeMessages'
    const LOG_CONTEXT = { partyId: id }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const path = `messages/events/${id}`
      const repository = this.getRepos(path)

      const fn = last(repository)?.on('child_added', data => {
        const LOG_METHOD = 'MessageClient.subscribeMessages.callback'

        try {
          this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

          let events = (data.val() ?? []) as MessageEvent[]
          events = Object.values(events ?? {})
            ?.flat()
            .filter(event => {
              return event.payload?.message?.timestamp > timestamp
            })
          if (events && events.length) {
            callback(events)
          }
        } catch (error) {
          this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
        } finally {
          this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
        }
      })

      const token = `${path}-${++this.subsCount}`
      fn && this.subsMap.set(token, fn)
      // console.log('subscribe', this.subsMap)
      return token
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
      throw error
    } finally {
      this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  unsubscribe(token: string) {
    const LOG_METHOD = 'MessageClient.unsubscribe'
    const LOG_CONTEXT = { token }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const fn = this.subsMap.get(token)
      fn && fn()
      this.subsMap.delete(token)
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
    } finally {
      this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }
}
