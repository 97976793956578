import { Party } from '../party'
import { ErrorSpec } from '../utils/error'
import { Id } from '../utils/primitives'
import { CommandReducer, EventSpec, MessageCommand } from './commands'

const metadata = (to: Id<'user'>, command: MessageCommand) => ({
  ...command.metadata,
  target: to,
  createdAt: command.metadata?.createdAt ?? '',
  createdBy: command.metadata?.createdBy ?? '',
  timestamp: command.metadata?.timestamp as unknown as number,
})

export const commandReducer = (
  commands: MessageCommand[],
  parties: Record<string, Party>,
) => {
  const result = new Map<string, EventSpec<'messageReceived'>[]>()

  commands
    .map(command => {
      let events: Map<string, EventSpec<'messageReceived'>[]>
      const type = command.type
      switch (type) {
        case 'sendMessage':
          events = sendMessage(command, parties)
          break
        default:
          throw {
            name: 'UnexpectedMessageCommandType',
            message: `Command type ${type} is not expected for message`,
            target: 'command.type',
            stack: new Error().stack,
          } as ErrorSpec
      }
      return events
    })
    .forEach(map => {
      map.forEach((item, to) => {
        let events = result.get(to)
        if (!events) {
          events = []
          result.set(to, events)
        }
        events.push(...item)
      })
    })
  return result
}

const sendMessage: CommandReducer<'sendMessage', 'messageReceived'> = (
  command,
  parties,
) => {
  const {
    payload: { message },
  } = command
  const {
    guest: guestParty,
    merchants: merchantsIds,
    runner: runnerId,
    ...context
  } = message.context

  const map = new Map<string, EventSpec<'messageReceived'>[]>()

  const targets = [
    ...message.to.map(to => (typeof to === 'string' ? to : to.party?.id)),
    message.from,
  ]

  const guest = guestParty && (parties[guestParty.id] as Party<'guest'>)
  const runner = runnerId && (parties[runnerId] as Party<'runner'>)
  const merchants = merchantsIds
    ?.map(id => parties[id] as Party<'merchant'>)
    .filter(merchant => merchant)

  targets.forEach(target => {
    const m = metadata(target, command)
    map.set(target, [
      {
        type: 'messageReceived',
        payload: {
          message: {
            ...message,
            timestamp: m.timestamp,
            context: {
              ...context,
              ...(guest ? { guest } : {}),
              ...(merchants ? { merchants } : {}),
              ...(runner ? { runner } : {}),
            },
          },
        },
        metadata: m,
      },
    ])
  })
  return map
}
