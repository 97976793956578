import { RouteComponentProps } from '@reach/router'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import { Box, Button, Container, Text, VStack } from '@chakra-ui/react'
import { GoogleAuthProvider, PhoneAuthProvider, auth } from '../firebase/compat'
import { useAuthState } from 'react-firebase-hooks/auth'

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    {
      provider: PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        size: 'invisible',
      },
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: (result, redirectUrl) => {
      const user = result.user
      const credential = result.credential
      const additionalUserInfo = result.additionalUserInfo
      const isNewUser = additionalUserInfo.isNewUser
      const providerId = additionalUserInfo.providerId
      const operationType = result.operationType
      console.log('signInSuccessWithAuthResult', {
        user,
        credential,
        additionalUserInfo,
        operationType,
        result,
        redirectUrl,
      })
      return true
    },
    signInFailure: error => {
      return console.error(error)
    },
    uiShown: () => {
      //document.getElementById('loader').style.display = 'none'
    },
  },
} as firebaseui.auth.Config

const ui = new firebaseui.auth.AuthUI(auth)
//ui.start('#firebaseui-auth-container', uiConfig)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HomeScreen = (props: RouteComponentProps) => {
  const [user, loading, error] = useAuthState(auth)

  return (
    <VStack height="100vh" alignItems="center" justifyContent="center">
      {loading ? (
        <Text>(...)</Text>
      ) : error ? (
        <Text>{String(error)}</Text>
      ) : user ? (
        <>
          <Text style={{ fontSize: 8 }}></Text>
          <Button
            onClick={() => {
              void auth.signOut()
            }}>
            signOut
          </Button>
        </>
      ) : (
        <Box gap={40}>
          <Container id="firebaseui-auth-container"></Container>
          <Container id="recaptcha-container"></Container>
        </Box>
      )}
    </VStack>
  )
}
