import {
  OrderService,
  OrderCommand,
  OrderEvent,
  OrderState,
  EventCallback,
  RefCallback,
  OrderRef,
  commandReducer,
  eventReducer,
  Order,
  EventSpec,
} from '@lib/brz-core-lib-type-ts/order'
import { Id } from '@lib/brz-core-lib-type-ts/utils/primitives'
import { Database, Logger, Session } from '../utils'
import set from '@lib/brz-core-lib-type-ts/utils/set'
import { PartyClient } from '../party'
import { ProductClient } from '../product'
import { MessageClient } from '../message'
import {
  OnlineParty,
  Party,
  PartyService,
} from '@lib/brz-core-lib-type-ts/party'
import { ProductService } from '@lib/brz-core-lib-type-ts/product'
import {
  MessageCommand,
  MessageService,
} from '@lib/brz-core-lib-type-ts/message'
import { EnvType, getSuffix, last } from '@lib/brz-core-lib-type-ts/utils'
import { timestamp } from '@lib/brz-core-lib-type-ts/utils/timestamp'
import axios from 'axios'

export class OrderClient implements OrderService {
  prefix: EnvType
  pushKey: string
  session: Session
  db: Database
  logger: Logger
  partyService: PartyService
  productService: ProductService
  messageService: MessageService
  state: Record<string, OrderState> = {}

  constructor({
    prefix,
    session,
    logger,
    db,
    partyApi,
    productApi,
    pushKey,
    messageService,
  }: {
    prefix: EnvType
    session: Session
    logger: Logger
    db: Database
    partyApi: string
    productApi: string
    pushKey?: string
    messageService?: MessageService
  }) {
    this.prefix = prefix
    this.logger = logger
    this.session = session
    this.pushKey = pushKey
    this.db = db
    this.partyService = new PartyClient({
      prefix,
      session,
      db,
      partyApi,
      logger,
    })
    this.productService = new ProductClient({ session, productApi, logger })
    this.messageService =
      messageService ??
      new MessageClient({ prefix, logger, db, partyApi, productApi, session })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ld(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.d(message, {
      context,
      error,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  li(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.i(message, {
      context,
      error,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  le(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.e(message, {
      context,
      error,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lw(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.w(message, {
      context,
      error,
    })
  }

  getRepos(path: string) {
    const result = [
      this.db.ref([this.prefix, path].filter(item => item).join('/')),
    ]

    if (!this.prefix) {
      this.lw('Prefix not provided!', {})
      throw new Error('Prefix not provided!')
    }
    // if (this.prefix === 'prd') {
    //   result.push(this.db.ref(path))
    // }

    return result
  }

  async dispatch(id: Id<'order'>, commands: OrderCommand[]) {
    const LOG_METHOD = 'OrderClient.dispatch'
    const LOG_CONTEXT = { orderId: id }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const commandsRepository = this.getRepos(`orders/commands/${id}`)
      const eventsRepository = this.getRepos(`orders/events/${id}`)
      const refRepository = this.getRepos(`orders/ref/${id}`)
      const runnerAccepted = this.getRepos(`orders/accepted/runners/${id}`)

      const user = (await this.session?.getSession())?.uid

      commands = commands.map(command => ({
        ...command,
        metadata: {
          ...command.metadata,
          createdAt: command.metadata?.createdAt || timestamp(),
          createdBy: command.metadata?.createdBy || user || '', //TODO: handle non-auth
          timestamp: this.db.serverTimestamp(),
        },
      }))

      const events = await commandReducer(
        commands,
        this.partyService,
        this.productService,
      )

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ref = {} as any
      set(ref, 'version', this.db.serverTimestamp())
      set(ref, 'id', id)
      const status = (
        events.find(event => (event?.payload?.order as Order)?.status)?.payload
          ?.order as Order
      )?.status
      set(ref, 'status', status)

      const parties = events
        .map(event => [
          (event?.payload?.order as Order)?.guest?.id,
          (event?.payload?.order as Order)?.runner?.id,
          ...((event?.payload?.order as Order)?.suborders?.map(
            suborder => suborder?.merchant?.id,
          ) ?? []),
        ])
        .flat()
        .filter(id => !!id)

      const acceptedOrders = commands
        .map(command =>
          command.type === 'acceptByRunner' &&
          command.payload.order.id &&
          command.payload.order.runner?.id
            ? {
                order: command.payload.order.id,
                runner: command.payload.order.runner?.id,
                ...command.metadata,
              }
            : undefined,
        )
        .filter(item => item)

      await Promise.all([
        refRepository.map(repository => repository.update(ref)),
        ...commands
          .map(command =>
            commandsRepository.map(repository => repository.push(command)),
          )
          .flat(),
        ...events
          .map(event =>
            eventsRepository.map(repository => repository.push(event)),
          )
          .flat(),
        ...parties.map(
          party =>
            this.getRepos(`orders/parties/${party}/${id}`)
              .map(repository => repository.set(ref))
              .flat(),
          ...acceptedOrders.map(
            item =>
              item && runnerAccepted.map(repository => repository.push(item)),
          ),
        ),
      ])

      const submitOrders = commands.filter(command => {
        return (
          (command.type === 'submitOrder' || command.type === 'askRunner') &&
          command.payload.order.id
        )
      })

      if (submitOrders.length) {
        last(eventsRepository)
          ?.orderByChild('type')
          .equalTo('orderSaved')
          .limitToLast(1)
          .on('child_added', snapshot => {
            const event = snapshot.val() as EventSpec<'orderSaved'>
            const merchants = event?.payload?.order?.suborders?.map(
              suborder => suborder.merchant,
            )
            const guest = event?.payload?.order?.guest

            const init = async () => {
              const runners = await this.partyService.findOnlineRunners(
                event?.payload?.order?.location,
              )
              this.li(
                `${LOG_METHOD}:sendMessagesRunnerToAcceptOrder for #${getSuffix(
                  id,
                )}`,
                {
                  ...LOG_CONTEXT,
                  runners,
                  merchants,
                  guest,
                },
              )
              void this.messageService.dispatch('system', [
                sendMessagesRunnerToAcceptOrder(
                  id,
                  guest,
                  merchants,
                  runners,
                  this.pushKey,
                  this.li,
                ),
              ])
            }
            void init()
          })
      }

      if (acceptedOrders.length) {
        last(runnerAccepted)
          // .orderByChild('timestamp')
          // .limitToFirst(1)
          ?.on('value', snapshot => {
            const child = snapshot.val()

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const values = Object.values(child ?? {}).sort(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (o1: any, o2: any) => o1.timestamp - o2.timestamp,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any[]

            this.li(
              `${LOG_METHOD}:runnerAccepted:first for #${getSuffix(id)}`,
              {
                ...LOG_CONTEXT,
                runnerQueue: (values?.map
                  ? values.map(
                      value =>
                        `${value.timestamp}-${value.createdAt}-${value.runner}`,
                    )
                  : []
                ).join(', \n'),
              },
            )

            const value = values?.length ? values[0] : {}
            const assignedRunner = value?.runner as Id<'party'>
            const order = value?.order as Id<'order'>
            acceptedOrders.forEach(item => {
              if (
                item &&
                item.order === order &&
                item.runner === assignedRunner
              ) {
                this.li(`${LOG_METHOD}:assignedRunner for #${getSuffix(id)}`, {
                  ...LOG_CONTEXT,
                  item,
                  child,
                })
                void this.dispatch(id, [
                  {
                    type: 'assignRunner',
                    payload: {
                      order: {
                        id: item.order,
                        runner: {
                          id: item.runner,
                        },
                      },
                    },
                  },
                ])
              }
            })
          })
      }

      commands.forEach(command => {
        if (
          command.type === 'assignRunner' &&
          command.payload.order?.id &&
          command.payload.order?.runner?.id
        ) {
          this.li(
            `${LOG_METHOD}:sendMessagesRunnerToJoinOrder for #${getSuffix(
              command.payload?.order?.id,
            )}`,
            {
              ...LOG_CONTEXT,
              runner: command.payload?.order?.runner?.id,
            },
          )

          void this.messageService.dispatch('system', [
            sendMessagesRunnerToJoinOrder(
              command.payload?.order?.id,
              command.payload?.order?.runner?.id,
            ),
          ])
        }
      })
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  subsMap = new Map<string, () => void>()
  subsCount = 0

  subscribeOrders(party: Id<'party'>, callback: RefCallback) {
    const LOG_METHOD = 'OrderClient.subscribeOrders'
    const LOG_CONTEXT = { partyId: party }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const path = `orders/parties/${party}`
      const repository = last(this.getRepos(path))

      const tokens: string[] = []

      {
        const fn = repository?.on('child_added', data => {
          const LOG_METHOD = 'OrderClient.subscribeOrders.on:child_added'
          const LOG_CONTEXT = { partyId: party }

          try {
            const ref = data.val() as OrderRef
            callback(ref)
          } catch (error) {
            this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
            throw error
          } finally {
            this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
          }
        })
        const token = `${party}:${path}-${++this.subsCount}`
        fn && this.subsMap.set(token, fn)
        // console.log('subscribe', this.subsMap)
        tokens.push(token)
      }
      return tokens
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
      throw error
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  async retrieveOrder(party: Id<'party'>, id: Id<'order'>) {
    const LOG_METHOD = 'OrderClient.retrieveOrder'
    const LOG_CONTEXT = { orderId: id, partyId: party }

    const path = `orders/events/${id}`
    const repository = last(this.getRepos(path))

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const snapshot = await repository
        ?.orderByChild('metadata/timestamp')
        ?.get()

      const eventsList: OrderEvent[] = []

      snapshot?.forEach((eachSnapshot: any) =>
        eventsList.push(eachSnapshot.val() as OrderEvent),
      )

      let state = this.state[id]

      eventsList.forEach(eachEvent => {
        state = eventReducer(state ?? ({} as OrderState), [eachEvent])
      })

      this.state[id] = state

      return state
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
      throw error
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  subscribeOrder(party: Id<'party'>, id: Id<'order'>, callback: EventCallback) {
    const LOG_METHOD = 'OrderClient.subscribeOrder'
    const LOG_CONTEXT = { orderId: id, partyId: party }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const path = `orders/events/${id}`
      const repository = last(this.getRepos(path))

      const fn = repository
        ?.orderByChild('metadata/timestamp')
        .on('child_added', data => {
          const LOG_METHOD = 'OrderClient.subscribeOrder.on:child_added'
          const LOG_CONTEXT = { orderId: id, partyId: party }

          try {
            const event = data.val() as OrderEvent

            let state = this.state[id]
            state = eventReducer(state ?? ({} as OrderState), [event])
            this.state[id] = state

            callback(state, [event])
          } catch (error) {
            this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
            throw error
          } finally {
            this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
          }
        })
      const token = `${party}:${path}-${++this.subsCount}`
      fn && this.subsMap.set(token, fn)
      return [token]
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
      throw error
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  unsubscribe(tokens: string[]) {
    const LOG_METHOD = 'OrderClient.unsubscribe'
    const LOG_CONTEXT = { tokens: tokens.join(', ') }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      tokens.forEach(token => {
        try {
          const fn = this.subsMap.get(token)
          fn && fn()
          this.subsMap.delete(token)
        } catch (error) {
          console.error(error)
        }
      })
      // console.log('unsubscribe', this.subsMap)
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
    } finally {
      this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }
}

const sendMessagesRunnerToAcceptOrder = (
  order: Id<'order'>,
  guest: Party,
  merchants: Party[],
  runners: OnlineParty[],
  pushKey: string,
  li: any,
) => {
  runners.forEach(eachRunner => {
    eachRunner.sessions.forEach(eachSession => {
      if (eachSession.deviceToken) {
        void axios.post(
          'https://fcm.googleapis.com/fcm/send',
          {
            to: eachSession.deviceToken,
            notification: {
              title: 'New order',
              body: 'A new order is available for you',
            },
            priority: 'high',
          },
          {
            headers: {
              Authorization: `key=${pushKey}`,
            },
          },
        )

        li(`Push notification:toRunner`, {
          runner: eachRunner,
          deviceToken: eachSession.deviceToken,
        })
      }
    })
  })

  return {
    type: 'sendMessage',
    payload: {
      message: {
        to: runners,
        from: 'system',
        text: `Please accept order {order}`,
        args: {
          order: order,
        },
        context: {
          action: 'runnerToAcceptOrder',
          order: order,
          suborder: order,
          guest,
          merchants: merchants.map(merchant => merchant.id),
        },
      },
    },
  } as MessageCommand
}

const sendMessagesRunnerToJoinOrder = (
  order: Id<'order'>,
  runner: Id<'party'>,
) => {
  return {
    type: 'sendMessage',
    payload: {
      message: {
        to: [runner],
        from: 'system',
        text: `Please join order {order}`,
        args: {
          order: order,
        },
        context: {
          action: 'runnerToJoinOrder',
          order: order,
        },
      },
    },
  } as MessageCommand
}
