import { ReactNode, useEffect, useState } from 'react'
import * as S from './styles'

interface NewOrderAlertProps {
  children: ReactNode
}

const orderSound = new Audio('/new_order_sound.mp3')

export const NewOrderAlert = ({ children }: NewOrderAlertProps) => {
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsShowing(currState => !currState)
      void orderSound.play()
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <S.Modal>{children}</S.Modal>
      <S.Layer isShowing={isShowing} />
    </>
  )
}
