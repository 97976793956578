import {
  GeoReferenceService,
  ReferenceResult,
} from '@lib/brz-core-lib-type-ts/georeference'
import { ApiSpec, HttpClient, Logger, Session } from '../utils'

export class GeoReferenceClient
  extends HttpClient
  implements GeoReferenceService
{
  logger: Logger

  constructor({
    referenceApi,
    session,
    logger,
  }: {
    referenceApi: string
    session?: Session
    logger: Logger
  }) {
    super(referenceApi, session, logger)
    this.logger = logger
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ld(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.d(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  li(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.i(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  le(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.e(message, { context, error })
  }

  async getStaticReferencesByLatLong(
    latitude: number,
    longitude: number,
    latitudeDelta: number,
    longitudeDelta: number,
  ) {
    const LOG_METHOD = 'GeoReferenceClient.getStaticReferencesByLatLong'
    const LOG_CONTEXT = {
      latitude,
      longitude,
      latitudeDelta,
      longitudeDelta,
    }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const response = await this.instance.get<ApiSpec<ReferenceResult[]>>('', {
        params: { latitude, longitude, latitudeDelta, longitudeDelta },
      })

      return response.data.value
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
      throw error
    } finally {
      this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }
}
