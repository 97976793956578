import { EnvType } from '@lib/brz-core-lib-type-ts/utils'

type ConfigEntry = {
  ENV: EnvType
  VERSION: string
  GEOREFERENCE_API: string
  PARTY_API: string
  USER_API: string
  PRODUCT_API: string
  PRICE_API: string
  FB_API_KEY: string
  FB_AUTH_DOMAIN: string
  FB_DATABASE_URL: string
  FB_PROJECT_ID: string
  FB_STORAGE_BUCKET: string
  FB_MESSAGING_SENDER_ID: string
  FB_APP_ID: string
  FB_MEASUREMENT_ID: string
}

type Config = Partial<Record<EnvType, ConfigEntry>>

const DEFAULT_ENV = 'stg'

const CONFIG: Config = {
  dev: {
    ENV: 'dev',
    VERSION: 'brz-partner-app-web-react@0.1.1+10',
    GEOREFERENCE_API:
      'https://dev-brz-core-api-cf.beneagis.workers.dev/api/georeferences',
    PARTY_API: 'https://dev-brz-core-api-cf.beneagis.workers.dev/api/parties',
    USER_API: 'https://dev-brz-core-api-cf.beneagis.workers.dev/api/users',
    PRODUCT_API:
      'https://dev-brz-core-api-cf.beneagis.workers.dev/api/products',
    PRICE_API: 'https://dev-brz-core-api-cf.beneagis.workers.dev/api/price',
    FB_API_KEY: 'AIzaSyCS2Zj_wsNF9bWxKcSu7XYzgAIf2RRd_1M',
    FB_AUTH_DOMAIN: 'dev-brz-001.firebaseapp.com',
    FB_DATABASE_URL: 'https://dev-brz-001-default-rtdb.firebaseio.com',
    FB_PROJECT_ID: 'dev-brz-001',
    FB_STORAGE_BUCKET: 'dev-brz-001.appspot.com',
    FB_MESSAGING_SENDER_ID: '418111095839',
    FB_APP_ID: '1:418111095839:web:24ba2a82c2aeb7de47012a',
    FB_MEASUREMENT_ID: 'G-BTWPL4NBQW',
  },
  tst: {
    ENV: 'tst',
    VERSION: 'brz-partner-app-web-react@0.1.1+10',
    GEOREFERENCE_API:
      'https://tst-brz-core-api-cf.beneagis.workers.dev/api/georeferences',
    PARTY_API: 'https://tst-brz-core-api-cf.beneagis.workers.dev/api/parties',
    USER_API: 'https://tst-brz-core-api-cf.beneagis.workers.dev/api/users',
    PRODUCT_API:
      'https://tst-brz-core-api-cf.beneagis.workers.dev/api/products',
    PRICE_API: 'https://tst-brz-core-api-cf.beneagis.workers.dev/api/price',
    FB_API_KEY: 'AIzaSyDRLsnZwSd56FLEEcID6-plY3ZV8dnfz4c',
    FB_AUTH_DOMAIN: 'beach-runnerz-dev.firebaseapp.com',
    FB_DATABASE_URL: 'https://beach-runnerz-dev-default-rtdb.firebaseio.com',
    FB_PROJECT_ID: 'beach-runnerz-dev',
    FB_STORAGE_BUCKET: 'beach-runnerz-dev.appspot.com',
    FB_MESSAGING_SENDER_ID: '52864339146',
    FB_APP_ID: '1:52864339146:web:e37a335e1f279bcf3fb833',
    FB_MEASUREMENT_ID: 'G-YNY8E1TX56',
  },
  stg: {
    ENV: 'stg',
    VERSION: 'brz-partner-app-web-react@0.1.1+10',
    GEOREFERENCE_API:
      'https://stg-brz-core-api-cf.beneagis.workers.dev/api/georeferences',
    PARTY_API: 'https://stg-brz-core-api-cf.beneagis.workers.dev/api/parties',
    USER_API: 'https://stg-brz-core-api-cf.beneagis.workers.dev/api/users',
    PRODUCT_API:
      'https://stg-brz-core-api-cf.beneagis.workers.dev/api/products',
    PRICE_API: 'https://stg-brz-core-api-cf.beneagis.workers.dev/api/price',
    FB_API_KEY: 'AIzaSyApvo000umPsSkXxdxo8M8xpduokcjBRrc',
    FB_AUTH_DOMAIN: 'stg-brz-001.firebaseapp.com',
    FB_DATABASE_URL: 'https://stg-brz-001-default-rtdb.firebaseio.com',
    FB_PROJECT_ID: 'stg-brz-001',
    FB_STORAGE_BUCKET: 'stg-brz-001.appspot.com',
    FB_MESSAGING_SENDER_ID: '125612558856',
    FB_APP_ID: '1:125612558856:web:21e0632502dc7c880a1fbd',
    FB_MEASUREMENT_ID: 'G-XPF62M9Z6F',
  },
  prd: {
    ENV: 'prd',
    VERSION: 'brz-partner-app-web-react@0.1.1+10',
    GEOREFERENCE_API:
      'https://brz-core-api-cf.beneagis.workers.dev/api/georeferences',
    PARTY_API: 'https://brz-core-api-cf.beneagis.workers.dev/api/parties',
    USER_API: 'https://brz-core-api-cf.beneagis.workers.dev/api/users',
    PRODUCT_API: 'https://brz-core-api-cf.beneagis.workers.dev/api/products',
    PRICE_API: 'https://brz-core-api-cf.beneagis.workers.dev/api/price',
    FB_API_KEY: 'AIzaSyD0xUetRvZ91TzVSosM1YKTADifm_c4DkE',
    FB_AUTH_DOMAIN: 'prd-brz-001.firebaseapp.com',
    FB_DATABASE_URL: 'https://prd-brz-001-default-rtdb.firebaseio.com',
    FB_PROJECT_ID: 'prd-brz-001',
    FB_STORAGE_BUCKET: 'prd-brz-001.appspot.com',
    FB_MESSAGING_SENDER_ID: '1056922564611',
    FB_APP_ID: '1:1056922564611:web:76f0f49d52000e1057f636',
    FB_MEASUREMENT_ID: 'G-6F0GCSQY5J',
  },
}

const config = (key: keyof ConfigEntry, env?: keyof Config) =>
  CONFIG[env ?? DEFAULT_ENV][key]

export default config
