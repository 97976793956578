import {
  PartyService,
  PartyCommand,
  PartyEvent,
  PartyState,
  EventCallback,
  RefsCallback,
  PartyRefs,
  commandReducer,
  eventReducer,
  Party,
  PartyType,
  OnlineParty,
} from '@lib/brz-core-lib-type-ts/party'
import { Id } from '@lib/brz-core-lib-type-ts/utils/primitives'
import { Database, Logger, Session } from '../utils'
import set from '@lib/brz-core-lib-type-ts/utils/set'
import { ApiSpec, HttpClient } from '../utils'
import { EnvType, last } from '@lib/brz-core-lib-type-ts/utils'
import { UserSession } from '@lib/brz-core-lib-type-ts/user'

export class PartyClient extends HttpClient implements PartyService {
  prefix: EnvType
  logger: Logger
  db: Database
  state: Record<string, PartyState> = {}

  constructor({
    prefix,
    db,
    partyApi,
    session,
    logger,
  }: {
    prefix: EnvType
    db: Database
    partyApi: string
    session?: Session
    logger: Logger
  }) {
    super(partyApi, session, logger)
    this.prefix = prefix
    this.logger = logger
    this.db = db
  }

  async getParties(ids: Id<'party'>[]): Promise<Record<string, Party>> {
    const response = await this.instance.get<ApiSpec<Record<string, Party>>>(
      '',
      {
        params: { id: ids.join(',') },
      },
    )
    return response.data.value
  }

  async findPartyByType(type?: PartyType | undefined): Promise<Party[]> {
    const response = await this.instance.get<ApiSpec<Party[]>>('', {
      params: { type },
    })
    return response.data.value
  }

  async findOnlineRunners(): Promise<OnlineParty[]> {
    const runners = await this.findPartyByType('runner')

    const result = Promise.all(
      runners
        .map(runner =>
          this.getRepos(`parties/sessions/${runner.id}/runner`).map(async repo => ({
            party: runner,
            sessions: Object.values(
              (await (await repo.get()).val()) ?? {},
            ) as UserSession[],
          })),
        )
        .flat(),
    )

    return result
  }

  getRepos(path: string) {
    const result = [
      this.db.ref([this.prefix, path].filter(item => item).join('/')),
    ]

    if (!this.prefix) {
      this.lw('Prefix not provided!', {})
      throw new Error('Prefix not provided!')
    }
    // if (this.prefix === 'prd') {
    //   result.push(this.db.ref(path))
    // }

    return result
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ld(message: string, context?: Record<string, unknown>, error?: any) {
    this.logger?.d(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  li(message: string, context?: Record<string, unknown>, error?: any) {
    this.logger?.i(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  le(message: string, context?: Record<string, unknown>, error?: any) {
    this.logger?.e(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lw(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.w(message, { context, error })
  }

  async dispatch(id: Id<'party'>, commands: PartyCommand[]) {
    const LOG_METHOD = 'PartyClient.dispatch'
    const LOG_CONTEXT = { partyId: id }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)

      const commandsRepository = this.getRepos(`parties/commands/${id}`)
      const eventsRepository = this.getRepos(`parties/events/${id}`)
      const refRepository = this.getRepos(`parties/ref/${id}`)

      commands = commands.map(command => ({
        ...command,
        metadata: {
          ...command.metadata,
          createdAt: command.metadata?.createdAt || new Date().toISOString(),
          createdBy: command.metadata?.createdAt || 'TODO: send auth user',
          timestamp: this.db.serverTimestamp(),
        },
      }))
      const events = commandReducer(commands)

      const status =
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (events.find(event => (event as any)?.payload?.party?.status) as any)
          ?.payload?.party?.status
      const location =
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (events.find(event => (event as any)?.payload?.party?.location) as any)
          ?.payload?.party?.location

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updates = {} as any
      updates['version'] = this.db.serverTimestamp()
      set(updates, 'id', id)
      set(updates, 'status', status)
      set(updates, 'location', location)

      await Promise.all([
        ...commandsRepository.map(repository => repository.push(commands)),
        ...eventsRepository.map(repository => repository.push(events)),
        ...refRepository.map(repository => repository.update(updates)),
      ])
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  subsMap = new Map<string, () => void>()
  subsCount = 0

  subscribeParties(callback: RefsCallback) {
    const LOG_METHOD = 'PartyClient.subscribeParties'
    const LOG_CONTEXT = undefined

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)
      const path = `parties/ref`
      const repository = this.getRepos(path)

      const fn = last(repository)?.on('value', data => {
        const LOG_METHOD = 'PartyClient.subscribeParty.on:value'
        const LOG_CONTEXT = {}

        try {
          const refs = (data.val() ?? undefined) as PartyRefs
          callback(refs)
        } catch (error) {
          this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
          throw error
        } finally {
          this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
        }
      })

      const token = `${path}-${++this.subsCount}`
      fn && this.subsMap.set(token, fn)
      // console.log('subscribe', this.subsMap)
      return token
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
      throw error
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  subscribeParty(id: Id<'party'>, callback: EventCallback) {
    const LOG_METHOD = 'PartyClient.subscribeParty'
    const LOG_CONTEXT = { partyId: id }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)
      const path = `parties/events/${id}`
      const repository = this.getRepos(path)

      const fn = last(repository)?.on('child_added', data => {
        const LOG_METHOD = 'PartyClient.subscribeParty.on:child_added'
        const LOG_CONTEXT = { partyId: id }

        try {
          const events = (data.val() ?? []) as PartyEvent[]
          this.state[id] = eventReducer(
            this.state[id] ?? ({} as PartyState),
            events,
          )
          callback(this.state[id], events)
        } catch (error) {
          this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
          throw error
        } finally {
          this.ld(`${LOG_METHOD}:finally`, LOG_CONTEXT)
        }
      })
      const token = `${path}-${++this.subsCount}`
      fn && this.subsMap.set(token, fn)
      // console.log('subscribe', this.subsMap)
      return token
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
      throw error
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }

  unsubscribe(token: string) {
    const LOG_METHOD = 'MessageClient.unsubscribe'
    const LOG_CONTEXT = { token }

    try {
      this.ld(`${LOG_METHOD}:begin`, LOG_CONTEXT)
      const fn = this.subsMap.get(token)
      fn && fn()
      this.subsMap.delete(token)
    } catch (error) {
      this.le(`${LOG_METHOD}:error`, LOG_CONTEXT, error)
    } finally {
      this.li(`${LOG_METHOD}:finally`, LOG_CONTEXT)
    }
  }
}
