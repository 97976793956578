import { Party, PartyService } from '../party'
import { Product, ProductService } from '../product'
import { ErrorSpec } from '../utils/error'
import {
  CommandReducer,
  CommandSpec,
  CommandType,
  EventReducer,
  EventType,
  OrderCommand,
  OrderEvent,
  OrderState,
  SaveItem,
} from './commands'
import { ItemStatus, OrderStatus, Suborder, SuborderStatus } from './types'

export const eventReducer = (state: OrderState, events: OrderEvent[]) =>
  events.reduce((state, event) => {
    let result: OrderState
    const type = event.type
    switch (type) {
      case 'orderSaved':
        result = orderSaved(state, event)
        break
      case 'checkoutStarted':
        result = checkoutStarted(state, event)
        break
      case 'orderReceived':
        result = orderReceived(state, event)
        break
      case 'runnerAsked':
        result = runnerAsked(state, event)
        break
      case 'guestLocationUpdated':
        result = guestLocationUpdated(state, event)
        break
      case 'runnerLocationUpdated':
        result = runnerLocationUpdated(state, event)
        break
      case 'orderAcceptedByRunner':
        result = orderAcceptedByRunner(state, event)
        break
      case 'runnerAssigned':
        result = runnerAssigned(state, event)
        break
      case 'preparationStarted':
        result = preparationStarted(state, event)
        break
      case 'preparationComplete':
        result = preparationComplete(state, event)
        break
      case 'pickupStarted':
        result = pickupStarted(state, event)
        break
      case 'pickupComplete':
        result = pickupComplete(state, event)
        break
      case 'deliveryStarted':
        result = deliveryStarted(state, event)
        break
      case 'deliveryComplete':
        result = deliveryComplete(state, event)
        break
      case 'serviceStarted':
        result = serviceStarted(state, event)
        break
      case 'serviceComplete':
        result = serviceComplete(state, event)
        break
      case 'orderCanceledByPlatform':
        result = orderCanceledByPlatform(state, event)
        break
      case 'orderCanceledlByRunner':
        result = orderCanceledlByRunner(state, event)
        break
      case 'orderCanceledByMerchant':
        result = orderCanceledByMerchant(state, event)
        break
      default:
        throw {
          name: 'UnexpectedEventType',
          message: `Event type ${type} is not expected`,
          target: 'event.type',
          stack: new Error().stack,
        } as ErrorSpec
    }
    result = {
      ...result,
      order: {
        ...result.order,
        version: Number(event.metadata.timestamp),
      },
    }
    return result
  }, state)

const orderSaved: EventReducer<'orderSaved'> = (state, event) => ({
  ...state,
  order: {
    ...state?.order,
    ...event.payload?.order,
    timeline: {
      ...state?.order?.timeline,
      ...(state?.order?.timeline?.orderCreated
        ? {}
        : { orderCreated: event.metadata.createdAt }),
    },
  },
})

const checkoutStarted: EventReducer<'checkoutStarted'> = (state, event) => ({
  ...state,
  order: {
    ...state?.order,
    status: event.payload?.order?.status,
    suborders: state?.order?.suborders?.map(suborder => ({
      ...suborder,
      status: 'checkout',
      timeline: {
        ...state?.order?.timeline,
        orderCheckedout: event.metadata.createdAt,
      },
      items:
        suborder.items &&
        suborder.items?.map(item => ({
          ...item,
          status: 'checkout',
        })),
    })),
  },
})

const orderReceived: EventReducer<'orderReceived'> = (state, event) => {
  const suborders = suborderStatus(
    state?.order?.suborders,
    event.payload?.order?.status,
  )
  return {
    ...state,
    order: {
      ...state?.order,
      status: event.payload?.order?.status,
      timeline: {
        ...state?.order?.timeline,
        orderSubmited: event.metadata.createdAt,
      },
      ...(suborders ? { suborders } : {}),
    },
  }
}

const runnerAsked: EventReducer<'runnerAsked'> = state => state

const guestLocationUpdated: EventReducer<'guestLocationUpdated'> = (
  state,
  event,
) => ({
  ...state,
  order: {
    ...state?.order,
    guest: {
      ...state?.order?.guest,
      ...(event.payload?.order?.guest?.name
        ? { name: event.payload.order.guest.name }
        : {}),
      ...(event.payload?.order?.guest?.location
        ? { location: event.payload.order.guest.location }
        : {}),
    },
    ...(event.payload?.order?.deliveryInstructions
      ? { deliveryInstructions: event.payload.order.deliveryInstructions }
      : {}),
  },
})

const runnerLocationUpdated: EventReducer<'runnerLocationUpdated'> = (
  state,
  event,
) => ({
  ...state,
  order: {
    ...state?.order,
    ...(state?.order?.runner
      ? {
          runner: {
            ...state.order.runner,
            location: event.payload.order?.runner?.location,
          },
        }
      : {}),
  },
})

const orderAcceptedByRunner: EventReducer<'orderAcceptedByRunner'> = state =>
  state

const runnerAssigned: EventReducer<'runnerAssigned'> = (state, event) => ({
  ...state,
  order: {
    ...state?.order,
    ...(event.payload.order.runner
      ? {
          runner: {
            ...state?.order?.runner,
            ...event.payload.order.runner,
          },
        }
      : {}),
  },
})

const orderStatusState: (
  status: OrderStatus,
  eventType: EventType,
) => EventReducer<typeof eventType> = status => (state, event) => ({
  ...state,
  order: {
    ...state?.order,
    status,
    timeline: {
      ...state?.order?.timeline,
      ...(event.type === 'deliveryComplete'
        ? { orderDelivered: event.metadata.createdAt }
        : {}),
    },
    suborders: state?.order?.suborders?.map(suborder => ({
      ...suborder,
      status,
      items: suborder.items?.map(item => ({
        ...item,
        status,
      })),
    })),
  },
})

const suborderStatusState: (
  status: OrderStatus,
  eventType: EventType,
) => EventReducer<typeof eventType> = status => (state, event) => ({
  ...state,
  order: {
    ...state?.order,
    status,
    timeline: {
      ...state?.order?.timeline,
      ...(event.type === 'preparationStarted'
        ? { orderConfirmed: event.metadata.createdAt }
        : {}),
      ...(event.type === 'preparationComplete'
        ? { orderPrepared: event.metadata.createdAt }
        : {}),
      ...(event.type === 'pickupComplete'
        ? { orderPickedup: event.metadata.createdAt }
        : {}),
    },
    suborders: state?.order?.suborders?.map(suborder => ({
      ...suborder,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      status:
        suborder.id === (event.payload.order as any)?.suborder
          ? status
          : suborder.status,
      items: suborder.items?.map(item => ({
        ...item,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        status:
          suborder.id === (event.payload.order as any)?.suborder
            ? status
            : suborder.status,
      })),
    })),
  },
})

const preparationStarted = suborderStatusState(
  'waitingPreparation',
  'preparationStarted',
)
const preparationComplete = suborderStatusState(
  'preparationComplete',
  'preparationComplete',
)
const pickupStarted = suborderStatusState('waitingPickup', 'pickupStarted')
const pickupComplete = suborderStatusState('pickupComplete', 'pickupComplete')
const deliveryStarted = orderStatusState('waitingDelivery', 'deliveryStarted')
const deliveryComplete = orderStatusState(
  'deliveryComplete',
  'deliveryComplete',
)
const serviceStarted = orderStatusState('serviceInProgress', 'serviceStarted')
const serviceComplete = orderStatusState('serviceComplete', 'serviceComplete')
const orderCanceledByPlatform = orderStatusState(
  'canceledByPlatform',
  'orderCanceledByPlatform',
)
const orderCanceledlByRunner = orderStatusState(
  'canceledByRunner',
  'orderCanceledlByRunner',
)
const orderCanceledByMerchant = suborderStatusState(
  'canceledByMerchant',
  'orderCanceledByMerchant',
)

const metadata = <T extends CommandType>(command: CommandSpec<T>) => ({
  ...command.metadata,
  createdAt: command.metadata?.createdAt ?? '',
  createdBy: command.metadata?.createdBy ?? '',
  timestamp: command.metadata?.timestamp ?? {},
  version: 0,
})

export const commandReducer = async (
  commands: OrderCommand[],
  partyService: PartyService,
  productService: ProductService,
) => {
  return (
    await Promise.all(
      commands
        .map(async command => {
          let events: OrderEvent[]
          const type = command.type
          switch (type) {
            case 'saveOrder':
              events = await saveOrder(command, partyService, productService)
              break
            case 'checkoutOrder':
              events = await checkoutOrder(command)
              break
            case 'submitOrder':
              events = await submitOrder(command)
              break
            case 'askRunner':
              events = await askRunner(command)
              break
            case 'updateGuestLocation':
              events = await updateGuestLocation(command)
              break
            case 'updateRunnerLocation':
              events = await updateRunnerLocation(command)
              break
            case 'acceptByRunner':
              events = await acceptByRunner(command, partyService)
              break
            case 'assignRunner':
              events = await assignRunner(command, partyService)
              break
            case 'startPreparation':
              events = (await startPreparation(command)) as OrderEvent[]
              break
            case 'completePreparation':
              events = (await completePreparation(command)) as OrderEvent[]
              break
            case 'startPickup':
              events = (await startPickup(command)) as OrderEvent[]
              break
            case 'completePickup':
              events = (await completePickup(command)) as OrderEvent[]
              break
            case 'startDelivery':
              events = (await startDelivery(command)) as OrderEvent[]
              break
            case 'completeDelivery':
              events = (await completeDelivery(command)) as OrderEvent[]
              break
            case 'startService':
              events = (await startService(command)) as OrderEvent[]
              break
            case 'completeService':
              events = (await completeService(command)) as OrderEvent[]
              break
            case 'cancelByPlatform':
              events = (await cancelByPlatform(command)) as OrderEvent[]
              break
            case 'cancelByRunner':
              events = (await cancelByRunner(command)) as OrderEvent[]
              break
            case 'cancelByMerchant':
              events = (await cancelByMerchant(command)) as OrderEvent[]
              break
            default:
              throw {
                name: 'UnexpectedCommandType',
                message: `Command type ${type} is not expected`,
                target: 'command.type',
                stack: new Error().stack,
              } as ErrorSpec
          }
          return events
        })
        .flat(),
    )
  ).flat()
}

const productDescription = (product: Product, item: SaveItem) => {
  const selected = [...(item.options ?? []), ...(item.addons ?? [])]

  const result = Object.values({ ...product.options, ...product.addons })
    .map(option => {
      const values = Object.values(option.values)
        .map(value => (selected.includes(value.id) ? value.name : undefined))
        .filter(value => value)

      return values.length ? `${option.name}: ${values.join(', ')}` : undefined
    })
    .filter(value => value)
    .join('\n')
  console.log('productDescription', '\n' + result)
  return result
}

const saveOrder: CommandReducer<'saveOrder', 'orderSaved'> = async (
  command,
  partyService,
  productService,
) => {
  const {
    payload: {
      order: { items, ...order },
    },
  } = command

  const products = await productService?.getProducts(
    items.map(item => item.product),
  )
  const merchants =
    products &&
    Array.from(
      new Set(Object.values(products).map(product => product.merchants[0])),
    )
  const parties = await partyService?.getParties([
    order.guest.id,
    ...(merchants ?? []),
  ])

  if (parties && products && merchants) {
    const suborders: Suborder[] = merchants.map((merchant, index) => {
      return {
        id: `${order.id}-${index + 1}`,
        status: 'new' as SuborderStatus,
        merchant: parties[merchant] as Party<'merchant'>,
        items: items
          .filter(item => products[item.product].merchants[0] === merchant)
          .map(item => ({
            id: item.id,
            status: 'new' as ItemStatus,
            name: `${item.quantity}x ${products[item.product].name}`,
            description: productDescription(products[item.product], item),
            images: products[item.product].images,
          })),
      }
    })

    return [
      {
        type: 'orderSaved',
        payload: {
          order: {
            id: order.id,
            location: order.location,
            status: 'new' as OrderStatus,
            guest: {
              ...(parties[order.guest.id] as Party<'guest'>),
              ...order.guest,
            },
            suborders,
          },
        },
        metadata: metadata(command),
      },
    ]
  } else {
    return []
  }
}

const checkoutOrder: CommandReducer<
  'checkoutOrder',
  'checkoutStarted'
> = command => {
  const { payload } = command
  return Promise.resolve([
    {
      type: 'checkoutStarted',
      payload: {
        ...payload,
        order: {
          ...payload.order,
          status: 'checkout',
        },
      },
      metadata: metadata(command),
    },
  ])
}

const submitOrder: CommandReducer<'submitOrder', 'orderReceived'> = command => {
  const { payload } = command
  return Promise.resolve([
    {
      type: 'orderReceived',
      payload: {
        ...payload,
        order: {
          ...payload.order,
          status: 'received',
        },
      },
      metadata: metadata(command),
    },
  ])
}

const askRunner: CommandReducer<'askRunner', 'runnerAsked'> = command => {
  const { payload } = command
  return Promise.resolve([
    {
      type: 'runnerAsked',
      payload,
      metadata: metadata(command),
    },
  ])
}

const updateGuestLocation: CommandReducer<
  'updateGuestLocation',
  'guestLocationUpdated'
> = command => {
  const { payload } = command
  return Promise.resolve([
    {
      type: 'guestLocationUpdated',
      payload,
      metadata: metadata(command),
    },
  ])
}

const updateRunnerLocation: CommandReducer<
  'updateRunnerLocation',
  'runnerLocationUpdated'
> = command => {
  const { payload } = command
  return Promise.resolve([
    {
      type: 'runnerLocationUpdated',
      payload,
      metadata: metadata(command),
    },
  ])
}

const acceptByRunner: CommandReducer<
  'acceptByRunner',
  'orderAcceptedByRunner'
> = async (command, partyService) => {
  const {
    payload: { order },
  } = command

  const runner = await partyService?.getParties([order.runner.id])

  return Promise.resolve(
    runner
      ? [
          {
            type: 'orderAcceptedByRunner',
            payload: {
              order: {
                ...order,
                runner: {
                  ...order.runner,
                  ...(runner[order.runner.id] as Party<'runner'>),
                },
              },
            },
            metadata: metadata(command),
          },
        ]
      : [],
  )
}

const assignRunner: CommandReducer<'assignRunner', 'runnerAssigned'> = async (
  command,
  partyService,
) => {
  const {
    payload: { order },
  } = command

  const runner = await partyService?.getParties([order.runner.id])

  return Promise.resolve(
    runner
      ? [
          {
            type: 'runnerAssigned',
            payload: {
              order: {
                ...order,
                runner: {
                  ...order.runner,
                  ...(runner[order.runner.id] as Party<'runner'>),
                },
              },
            },
            metadata: metadata(command),
          },
        ]
      : [],
  )
}

const orderStatusEvent: (
  commandType: CommandType,
  eventType: EventType,
  status: OrderStatus,
) => CommandReducer<typeof commandType, typeof eventType> =
  (commandType, eventType, status) => command => {
    const { payload } = command
    return Promise.resolve([
      {
        type: eventType,
        payload: {
          ...payload,
          order: {
            ...payload.order,
            status,
          },
        },
        metadata: metadata(command),
      },
    ])
  }

const startPreparation = orderStatusEvent(
  'startPreparation',
  'preparationStarted',
  'waitingPreparation',
)
const completePreparation = orderStatusEvent(
  'completePreparation',
  'preparationComplete',
  'preparationComplete',
)
const startPickup = orderStatusEvent(
  'startPickup',
  'pickupStarted',
  'waitingPickup',
)
const completePickup = orderStatusEvent(
  'completePickup',
  'pickupComplete',
  'pickupComplete',
)
const startDelivery = orderStatusEvent(
  'startDelivery',
  'deliveryStarted',
  'waitingDelivery',
)
const completeDelivery = orderStatusEvent(
  'completeDelivery',
  'deliveryComplete',
  'deliveryComplete',
)
const startService = orderStatusEvent(
  'startService',
  'serviceStarted',
  'serviceInProgress',
)
const completeService = orderStatusEvent(
  'completeService',
  'serviceComplete',
  'serviceComplete',
)
const cancelByPlatform = orderStatusEvent(
  'cancelByPlatform',
  'orderCanceledByPlatform',
  'canceledByPlatform',
)
const cancelByRunner = orderStatusEvent(
  'cancelByRunner',
  'orderCanceledlByRunner',
  'canceledByPlatform',
)
const cancelByMerchant = orderStatusEvent(
  'cancelByMerchant',
  'orderCanceledByMerchant',
  'canceledByMerchant',
)

const suborderStatus = (suborders: Suborder[], status: OrderStatus) =>
  suborders?.map(suborder => ({
    ...suborder,
    status,
    ...(suborder.items
      ? {
          items: suborder.items.map(item => ({
            ...item,
            status,
          })),
        }
      : {}),
  }))
