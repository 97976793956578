import React, { ReactElement, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  VStack,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Input,
  Tooltip,
  Select,
} from '@chakra-ui/react'
import { MessageClient } from '@lib/brz-core-lib-sdk-ts/message'
import {
  MdAddComment,
  MdChatBubble,
  MdMarkChatRead,
  MdMarkChatUnread,
  MdQuickreply,
} from 'react-icons/md'
import { DatabaseImpl } from '../../../firebase/modular'
import { SessionImpl } from '../../../firebase/compat'
import {
  EventCallback,
  MessageCommand,
  Message,
} from '@lib/brz-core-lib-type-ts/message'
import format from '@lib/brz-core-lib-type-ts/utils/format'
import config from '../../../config'
import { Layout } from 'src/ui/Layout'
import { Party } from '@lib/brz-core-lib-type-ts/party'
import { PartyClient } from '@lib/brz-core-lib-sdk-ts/party'
import { EnvType } from '@lib/brz-core-lib-type-ts/utils'
import { logger } from '@lib/brz-core-lib-sdk-ts/utils'

logger.setApp({ name: 'brz-partner-app-web-react' })
logger.addOptions({
  console: { threshold: 'warn' },
  sentry: { threshold: 'warn' },
})

const session = new SessionImpl()
const db = new DatabaseImpl(logger)

const messageClient = new MessageClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  productApi: config('PRODUCT_API'),
  partyApi: config('PARTY_API'),
})

const partyClient = new PartyClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  partyApi: config('PARTY_API'),
})

let count = 0
const initialOrder = '' //ID('order', 'mock')
const initialParty = ''

const map = new Map<string, string>()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AdminMessagesScreen = (props: RouteComponentProps) => {
  const [messages, setMessages] = useState([] as Message[])
  const [party, setParty] = useState(initialParty)
  const [order, setOrder] = useState(initialOrder)
  const [parties, setParties] = useState<Party[]>()

  useEffect(() => {
    const init = async () => {
      const parties = await partyClient.findPartyByType()
      setParties(parties)
    }

    init().catch(console.error)
  }, [])

  useEffect(() => {
    setMessages([])
    const eventCallback: EventCallback = events => {
      messages.push(...events.map(event => event.payload.message))
      messages.sort(
        (message1, message2) => message2.timestamp - message1.timestamp,
      )

      setMessages([...messages])
    }
    const token = messageClient.subscribeMessages(
      party,
      Date.now(),
      eventCallback,
    )

    return () => {
      messageClient.unsubscribe(token)
      Object.values(map).forEach(token => {
        messageClient.unsubscribe(token as string)
      })
    }
  }, [messageClient, party])

  return (
    <Layout>
      <VStack mt={8} spacing={8} align="flex-start">
        <HStack spacing={4}>
          <Tooltip label="Party Id">
            <Select
              value={party}
              onChange={event => setParty(event.target.value.trim())}>
              <>
                {parties?.map(party => (
                  <option value={party.id} key={party.id}>
                    {party.name} ({party.type})
                  </option>
                ))}
              </>
            </Select>
          </Tooltip>
          <Tooltip label="Order Id">
            <Input
              fontSize="xs"
              value={order}
              onChange={event => setOrder(event.target.value.trim())}
            />
          </Tooltip>
          <Tooltip label="Send Message">
            <IconButton
              aria-label="Send Message"
              title="Send Message"
              icon={<MdChatBubble />}
              onClick={() => {
                messageClient
                  .dispatch(party, [SEND_MESSAGES_COMMAND(party, ++count)])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>
          <Tooltip label="Send Message to Many">
            <IconButton
              aria-label="Send Message to Many"
              title="Send Message to Many"
              icon={<MdAddComment />}
              onClick={() => {
                messageClient
                  .dispatch(party, [
                    SEND_MESSAGES_TO_MANY_COMMAND(party, ++count),
                  ])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>
          <Tooltip label="Notify Runner to Accept Order">
            <IconButton
              aria-label="Notify Runner to Accept Order"
              title="Notify Runner to Accept Order"
              icon={<MdMarkChatUnread />}
              onClick={() => {
                messageClient
                  .dispatch(party, [
                    SEND_MESSAGES_RUNNER_TO_ACCEPT_ORDER(party, ++count, order),
                  ])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>
          <Tooltip label="Send Runner Order Acceptance">
            <IconButton
              aria-label="Send Runner Order Acceptance"
              title="Send Runner Order Acceptance"
              icon={<MdMarkChatRead />}
              onClick={() => {
                messageClient
                  .dispatch(party, [
                    SEND_MESSAGE_RUNNER_ACCEPT_ORDER(party, ++count, order),
                  ])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>

          <Tooltip label="Notify Runner to Join Order">
            <IconButton
              aria-label="Notify Runner to Join Order"
              title="Notify Runner to Join Order"
              icon={<MdQuickreply />}
              onClick={() => {
                messageClient
                  .dispatch(party, [
                    SEND_MESSAGE_RUNNER_TO_JOIN_ORDER(party, ++count, order),
                  ])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>
          <Tooltip label="Notify Merchant to Accept Order">
            <IconButton
              aria-label="Notify Merchant to Accept Order"
              title="Notify Merchant to Accept Order"
              icon={<MdMarkChatUnread />}
              onClick={() => {
                messageClient
                  .dispatch(party, [
                    SEND_MESSAGE_MERCHANT_TO_ACCEPT_SUBORDER(
                      party,
                      ++count,
                      order,
                    ),
                  ])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>
          <Tooltip label="Send Merchant Order Acceptance">
            <IconButton
              aria-label="Send Merchant Order Acceptance"
              title="Send Merchant Order Acceptance"
              icon={<MdMarkChatRead />}
              onClick={() => {
                messageClient
                  .dispatch(party, [
                    SEND_MESSAGE_MERCHANT_ACCEPT_SUBORDER(
                      party,
                      ++count,
                      order,
                    ),
                  ])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>
          <Tooltip label="Notify Merchant to Join Order">
            <IconButton
              aria-label="Notify Merchant to Join Order"
              title="Notify Merchant to Join Order"
              icon={<MdQuickreply />}
              onClick={() => {
                messageClient
                  .dispatch(party, [
                    SEND_MESSAGE_MERCHANT_TO_JOIN_SUBORDER(
                      party,
                      ++count,
                      order,
                    ),
                  ])
                  .catch(error => console.error(error))
              }}
            />
          </Tooltip>
        </HStack>

        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>From</Th>
                <Th>To</Th>
                <Th>Text</Th>
                <Th>Action</Th>
                <Th>Timestamp</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                /*eslint-disable-next-line @typescript-eslint/no-unsafe-argument*/
                messages.map(
                  ({ to, from, text, timestamp, args, context }, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{from}</Td>
                      <Td>{to?.join(', ')}</Td>
                      <Td>{format(text, args)}</Td>
                      <Td title={JSON.stringify(context, undefined, 2)}>
                        {context?.action}
                      </Td>
                      <Td fontSize="xs">{new Date(timestamp).toISOString()}</Td>
                    </Tr>
                  ),
                )
              }
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Layout>
  )
}

type MockFn = (party: string, version: number, order?: string) => MessageCommand

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const action = (
  label: string,
  icon: ReactElement,
  party: string,
  order: string,
  fn: MockFn,
) => (
  <Tooltip label={label}>
    <IconButton
      aria-label={label}
      title={label}
      icon={icon}
      onClick={() => {
        messageClient
          .dispatch(party, [fn(party, ++count, order)])
          .catch(error => console.error(error))
      }}
    />
  </Tooltip>
)

const SEND_MESSAGES_COMMAND: MockFn = (party, version) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: [party],
      from: 'from-0',
      text: `Message v${version}`,
      args: {},
      context: {},
    },
  },
})

const SEND_MESSAGES_TO_MANY_COMMAND: MockFn = (party, version) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: [party, 'to-1'],
      from: 'from-0',
      text: `Message v${version}`,
      args: {},
      context: {},
    },
  },
})

const SEND_MESSAGES_RUNNER_TO_ACCEPT_ORDER: MockFn = (
  party,
  version,
  order,
) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: [party],
      from: '-',
      text: `Please accept order {order} v${version}`,
      args: {
        order: order,
      },
      context: {
        action: 'runnerToAcceptOrder',
        order: order,
        suborder: order,
        guest: {
          id: `guest-${party}`,
          name: 'Guest',
          type: 'guest',
        },
        merchants: [
          'party__mock__piola',
          'party__mock__latin-cafe',
          'party__mock__41-pizza-bakery',
        ],
      },
    },
  },
})

const SEND_MESSAGE_RUNNER_ACCEPT_ORDER: MockFn = (party, version?, order?) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: ['-'],
      from: party,
      text: `Accepting {order} v${version}`,
      args: {
        order: order,
        runner: party,
      },
      context: {
        action: 'runnerAcceptOrder',
        order: order,
        runner: party,
      },
    },
  },
})

const SEND_MESSAGE_RUNNER_TO_JOIN_ORDER: MockFn = (
  party,
  version?,
  order?,
) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: [party],
      from: '-',
      text: `You've been assigned to order {order} v${version}`,
      args: {
        order: order,
        runner: party,
      },
      context: {
        action: 'runnerToJoinOrder',
        order: order,
      },
    },
  },
})

const SEND_MESSAGE_MERCHANT_TO_ACCEPT_SUBORDER: MockFn = (
  party,
  version,
  order,
) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: [party],
      from: '-',
      text: `Please accept order {order} v${version}`,
      args: {
        order: order,
      },
      context: {
        action: 'merchantAcceptSuborder',
        order: order,
        suborder: order,
        guest: {
          id: `guest-${party}`,
          name: 'Guest',
          type: 'guest',
        },
        merchants: [
          'party__mock__piola',
          'party__mock__latin-cafe',
          'party__mock__41-pizza-bakery',
          'party__mock__41-pizza-bakery',
        ],
      },
    },
  },
})

const SEND_MESSAGE_MERCHANT_ACCEPT_SUBORDER: MockFn = (
  party,
  version?,
  order?,
) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: ['-'],
      from: party,
      text: `Accepting {order} v${version}`,
      args: {
        order: order,
        suborder: order,
        merchants: [party],
      },
      context: {
        action: 'merchantAcceptSuborder',
        order: order,
        suborder: order,
      },
    },
  },
})

const SEND_MESSAGE_MERCHANT_TO_JOIN_SUBORDER: MockFn = (
  party,
  version?,
  order?,
) => ({
  type: 'sendMessage',
  payload: {
    message: {
      to: [party],
      from: '-',
      text: `You've been assigned to order {order} v${version}`,
      args: {
        order: order,
        suborder: order,
        merchants: [party],
      },
      context: {
        action: 'merchantToJoinSuborder',
        order: order,
        suborder: order,
      },
    },
  },
})
