import { UserSession } from '@lib/brz-core-lib-type-ts/user'
import {
  convertError,
  deleteUndefined,
  EnvType,
  ErrorSpec,
} from '@lib/brz-core-lib-type-ts/utils'
import { timestamp } from '@lib/brz-core-lib-type-ts/utils/timestamp'
import { captureMessage, captureException, withScope } from '@sentry/core'
import { Severity } from '@sentry/types'
import { CORE_SDK_RELEASE } from '../release'
import { CORE_TYPE_RELEASE } from '@lib/brz-core-lib-type-ts/release'

export type Level = 'debug' | 'info' | 'warn' | 'error'

type AppInfo = {
  name?: string
  env?: EnvType
  isTesting?: boolean
  reactNativeVersion?: string
  os?: Os
  device?: Device
}

type Os = {
  //https://docs.expo.dev/versions/v46.0.0/react-native/platform/#properties
  name?: string
  version?: string //android: Version, ios: osVersion
  build?: string
  platformApiLevel?: number
  release?: string //android: Release, ios: systemName
}

type Device = {
  //https://docs.expo.dev/versions/latest/sdk/device/
  isDevice?: boolean
  serialNumber?: string
  fingerprint?: string
  model?: string
  brand?: string
  manufacturer?: string
  totalMemory?: number
  yearClass?: number
}

type Update = {
  //https://docs.expo.dev/versions/latest/sdk/updates/#constants
  channel?: string
  createdAt?: string
  isEmergencyLaunch?: boolean
  releaseChannel?: string
  runtimeVersion?: string
  updateId?: string
}

type Network = {
  //https://github.com/react-native-netinfo/react-native-netinfo#netinfostate
  type?: string
  isConnected?: boolean
  isInternetReachable?: boolean
  isWifiEnabled?: boolean
  isConnectionExpensive?: boolean
  //https://github.com/react-native-netinfo/react-native-netinfo#type-is-wifi
  strength?: number
  linkSpeed?: number
  rxLinkSpeed?: number
  txLinkSpeed?: number
  //https://github.com/react-native-netinfo/react-native-netinfo#type-is-cellular
  cellularGeneration?: string
  carrier?: string
}

export type LogData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
  context?: Record<string, unknown>
  user?: UserSession
  app?: AppInfo
  network?: Network
  update?: Update
}

type Options = {
  console?:
    | boolean
    | {
        active?: boolean
        threshold: Level
      }
  firebase?:
    | boolean
    | {
        active?: boolean
        threshold: Level
      }
  sentry?:
    | boolean
    | {
        active?: boolean
        threshold: Level
      }
}

type LogFn = (
  options: Options | undefined,
  level: Level,
  message: string,
  error: ErrorSpec | undefined,
  data: LogData,
) => void

const index = (level: Level) =>
  ['debug', 'info', 'warn', 'error'].indexOf(level)

const isActive = (
  option: undefined | boolean | { active?: boolean; threshold?: Level },
  level: Level,
) =>
  option !== false &&
  typeof option === 'object' &&
  option?.active !== false &&
  (!option?.threshold || index(level) >= index(option?.threshold))

const CONSOLE_LEVEL = {
  debug: 'DBUG',
  info: 'INFO',
  warn: 'WARN',
  error: 'ERRR',
}

export const consoleLog: LogFn = (options, level, message, error, data) => {
  const fn = console[level]

  isActive(options?.console, level) &&
    fn &&
    fn(CONSOLE_LEVEL[level], timestamp(), message, data, error)
}

const SENTRY_LEVELS = {
  debug: 'debug',
  info: 'info',
  warn: 'warning',
  error: 'error',
}

export const sentryLog: LogFn = (options, level, message, error, data) => {
  if (isActive(options?.sentry, level)) {
    const user = {
      id: data?.user?.uid,
      username: data?.user?.phone,
      email: data?.user?.email,
    }

    const context = deleteUndefined({
      contexts: {
        error: data.error,
        ...(data.context ? { context: data.context } : {}),
        ...(data.app ? { app: data.app } : {}),
        ...(data.network ? { network: data.network } : {}),
        ...(data.update ? { update: data.update } : {}),
        ...(data.user ? { user: data.user } : {}),
      },
      user,
      timestamp: timestamp(),
      level: SENTRY_LEVELS[level] as Severity,
    })
    if (level === 'error') {
      captureException(error, context)
    } else {
      captureMessage(message, context)
    }
  }
}

const sentryContexts = (contexts: Record<string, Record<string, unknown>>) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  Object.assign(
    {},
    ...(!contexts
      ? []
      : Object.entries(contexts)
          .filter(([, value]) => value)
          .map(([key, context]) => ({
            [key]: Object.assign(
              {},
              ...Object.entries(context)
                .filter(([, value]) => value)
                .map(([key, value]) => ({
                  [key]: JSON.stringify(value),
                })),
            ),
          }))),
  )

export class Logger {
  context: Record<string, unknown>
  user: UserSession | undefined
  app: AppInfo | undefined
  network: Network | undefined
  update: Update | undefined
  options: Options | undefined
  sentryUserIds = new Set<string>()
  sentrySessionIds = new Set<string>()
  sentryDeviceIds = new Set<string>()

  instances = new Map<number, LogFn>()

  count = 0

  constructor(options?: Options, app?: AppInfo) {
    this.app = app
    this.options = options
    this.context = { CORE_SDK_RELEASE, CORE_TYPE_RELEASE }
    this.addLog(consoleLog)
    this.addLog(sentryLog)
    withScope(scope => {
      scope?.getUser()?.id && this.sentryUserIds.add(scope?.getUser()?.id)
      scope?.getSession()?.sid &&
        this.sentrySessionIds.add(scope?.getSession()?.sid)
      scope?.getSession()?.did &&
        this.sentryDeviceIds.add(scope?.getSession()?.did)
    })
  }

  getSentryUserIds() {
    return (
      (this.sentryUserIds && this.sentryUserIds.size && Array.from(this.sentryUserIds).join(', ')) ||
      undefined
    )
  }

  getSentrySessionIds() {
    return (
      (this.sentrySessionIds && this.sentrySessionIds.size && Array.from(this.sentrySessionIds).join(', ')) ||
      undefined
    )
  }

  getSentryDeviceIds() {
    return (
      (this.sentryDeviceIds && this.sentryDeviceIds.size && Array.from(this.sentryDeviceIds).join(', ')) ||
      undefined
    )
  }

  addLog(fn: LogFn) {
    const count = ++this.count
    this.instances.set(count, fn)
    return count
  }

  removeLog(id: number) {
    return this.instances.delete(id)
  }

  d(message: string, data?: LogData) {
    this.log('debug', message, data)
  }

  i(message: string, data?: LogData) {
    this.log('info', message, data)
  }

  w(message: string, data?: LogData) {
    this.log('warn', message, data)
  }

  e(message: string, data?: LogData) {
    this.log('error', message, data)
  }

  log(level: Level, message: string, data?: LogData) {
    const error = convertError(data?.error)
    this.instances.forEach(log => {
      log(
        this.options,
        level,
        message,
        error,
        deleteUndefined(
          copy({
            error,
            context: merge(this.context, data?.context),
            user: merge(this.user, data?.user),
            app: merge(this.app, data?.app),
            network: merge(this.network, data?.network),
            update: merge(this.update, data?.update),
          }),
        ),
      )
    })
  }

  addOptions(option: Options) {
    this.options = copy({
      ...this.options,
      ...option,
    })
  }

  addContext(context: Record<string, unknown>) {
    this.context = copy({
      ...this.context,
      ...context,
    }) as unknown as Record<string, unknown>
  }

  setUser(user?: UserSession) {
    this.user = user ? copy(user) : undefined
  }

  setApp(app: AppInfo) {
    this.app = copy(app)
  }

  setNetwork(network: Network) {
    this.network = copy(network)
  }

  setUpdate(update: Update) {
    this.update = copy(update)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const copy = <T extends object>(o: object): T => JSON.parse(JSON.stringify(o))

const merge = <T extends object>(o1?: T, o2?: T): T | undefined =>
  o1 ? (o2 ? { ...o1, ...o2 } : o1) : o2 ? o2 : undefined

export const logger = new Logger()
