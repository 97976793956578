import Config from '../config'

const firebaseConfig = {
  apiKey: Config('FB_API_KEY'),
  authDomain: Config('FB_AUTH_DOMAIN'),
  databaseURL: Config('FB_DATABASE_URL'),
  projectId: Config('FB_PROJECT_ID'),
  storageBucket: Config('FB_STORAGE_BUCKET'),
  messagingSenderId: Config('FB_MESSAGING_SENDER_ID'),
  appId: Config('FB_APP_ID'),
  measurementId: Config('FB_MEASUREMENT_ID'),
}

export default firebaseConfig
