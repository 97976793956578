import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { Logger } from './Logger'
import { Session } from './Session'

export abstract class HttpClient {
  protected readonly instance: AxiosInstance
  protected readonly session?: Session
  protected readonly logger: Logger

  public constructor(
    baseURL: string,
    session: Session | undefined,
    logger: Logger,
  ) {
    this.instance = axios.create({ baseURL })
    this.session = session
    this.logger = logger

    this.initializeRequestInterceptor()
    this.initializeResponseInterceptor()
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    )
  }

  private handleRequest = async (request: AxiosRequestConfig) => {
    const authToken = await this.session?.getToken()
    if (authToken?.token) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${authToken?.token}`,
      }
    } else {
      const LOG_CONTEXT = {
        request: getRequest(request),
      }
      const user = await this.session?.getSession()
      this.logger?.d(
        `${method(request)} - Token not provided for ${url(request)} - Request`,
        { context: LOG_CONTEXT, user },
      )
    }
    return request as unknown
  }

  private handleResponse = async (response: AxiosResponse) => {
    const LOG_CONTEXT = {
      request: getRequest(response.config),
      response: getResponse(response),
    }

    const user = await this.session?.getSession()
    this.logger?.d(
      `${method(response.config)} - ${url(response.config)} - Response`,
      { context: LOG_CONTEXT, user },
    )
    return response as unknown
  }

  protected handleError = async (error: AxiosError) => {
    const LOG_CONTEXT = {
      request: getRequest(error.config),
      error: getError(error),
    }
    const user = await this.session?.getSession()
    this.logger?.e(`${method(error.config)} - ${url(error.config)} - Error`, {
      context: LOG_CONTEXT,
      user,
      error,
    })
    return Promise.reject(error)
  }
}

const getRequest = (request: AxiosRequestConfig) => ({
  url: request.url,
  baseURL: request.baseURL,
  params: request.params,
  data: request.data,
  headers: request.headers,
  method: request.method,
})

const getResponse = (response: AxiosResponse) => ({
  status: response.status,
  statusText: response.statusText,
  headers: response.headers,
})

const getError = (error: AxiosError) => ({
  name: error.name,
  code: error.code,
  message: error.message,
  stack: error.stack,
  cause: error.cause,
  isAxiosError: error.isAxiosError,
  ...getResponse(error.response as AxiosResponse),
})

const url = (request: AxiosRequestConfig) =>
  [request?.baseURL, ...(request?.url ? [request?.url] : [])].join('/')

const method = (request: AxiosRequestConfig) => request?.method?.toUpperCase()
