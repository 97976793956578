const timeScalars = [1000, 60, 60, 24, 7, 52]
const timeUnits = ['ms', 'secs', 'mins', 'hrs', 'days', 'weeks', 'years']

export const msAgo = (ms: number, dp = 0) => {
  let timeScalarIndex = 0,
    scaledTime = ms

  while (scaledTime > timeScalars[timeScalarIndex]) {
    scaledTime /= timeScalars[timeScalarIndex++]
  }

  return `${scaledTime.toFixed(dp)} ${timeUnits[timeScalarIndex]}`
}

export const dateAgo = (date: Date, dp = 0) => {
  const now = Date.now()
  const other = date.getTime()
  return msAgo(Math.max(0, now - other), dp)
}

export const dateBetweenStr = (start: string, end: string, dp = 0) => {
  if (!start || !end) return ''
  return dateBetween(new Date(start), new Date(end))
}

export const dateBetween = (start: Date, end: Date, dp = 0) => {
  if (!start || !end) return ''
  const now = end.getTime()
  const other = start.getTime()
  return msAgo(Math.max(0, now - other), dp)
}
