import { initializeApp } from 'firebase/app'
//import { getAnalytics } from 'firebase/analytics'
import {
  getDatabase,
  Database as FbDatabase,
  serverTimestamp,
  increment,
  ref,
  DataSnapshot,
  onValue,
  onChildAdded,
  set,
  push,
  update,
  startAt,
  query,
  Query,
  DatabaseReference,
  orderByChild,
  limitToLast,
  limitToFirst,
  equalTo,
  get,
} from 'firebase/database'
import { getAuth } from 'firebase/auth'
import {
  Database,
  Logger,
  Reference,
  Snapshot,
  Type,
} from '@lib/brz-core-lib-sdk-ts/utils'
import firebaseConfig from './firebaseConfig'

export const app = initializeApp(firebaseConfig)
//const analytics = getAnalytics(app)

export const db = getDatabase(app)
export const auth = getAuth(app)

export class DatabaseImpl implements Database {
  database: FbDatabase
  logger: Logger

  constructor(logger: Logger) {
    this.database = db
    this.logger = logger
  }

  serverTimestamp(): object {
    return serverTimestamp()
  }
  increment(delta: number): object {
    return increment(delta)
  }
  ref(path: string): Reference {
    const databaseURL = this.database.app.options.databaseURL
    return new ReferenceImpl(
      this.logger,
      ref(this.database, path),
      p(databaseURL, path),
    )
  }
}

const p = (url: string, path: string) => [url, path].join('/')

class ReferenceImpl implements Reference {
  reference: Query
  logger: Logger
  url: string

  constructor(logger: Logger, reference: Query, url: string) {
    this.reference = reference
    this.logger = logger
    this.url = url
  }

  startAt(value: string | number | boolean, key?: string) {
    return new ReferenceImpl(
      this.logger,
      query(this.reference, startAt(value, key)),
      p(this.url, `start:${key}=${value}`),
    )
  }

  orderByChild(path: string) {
    return new ReferenceImpl(
      this.logger,
      query(this.reference, orderByChild(path)),
      p(this.url, `order:${path}`),
    )
  }

  limitToLast(limit: number) {
    return new ReferenceImpl(
      this.logger,
      query(this.reference, limitToLast(limit)),
      p(this.url, `last:${limit}`),
    )
  }

  limitToFirst(limit: number) {
    return new ReferenceImpl(
      this.logger,
      query(this.reference, limitToFirst(limit)),
      p(this.url, `first:${limit}`),
    )
  }

  equalTo(value: number | string | boolean | null, key?: string) {
    return new ReferenceImpl(
      this.logger,
      query(this.reference, equalTo(value, key)),
      p(this.url, `equal:${key}=${value}`),
    )
  }

  private _log(
    message: string,
    context?: Record<string, unknown>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any,
  ) {
    // console.log({ message, context, user, error })
    this.logger?.log('info', message, { context, error })
  }

  async get() {
    this._log(`${this.url} - GET`)
    return new SnapshotImpl(await get(this.reference))
  }

  on(type: Type, callback: (snapshot: Snapshot) => void) {
    this._log(`${this.url} - ON ${type}`)
    switch (type) {
      case 'value':
        return onValue(this.reference, snapshot => {
          callback(new SnapshotImpl(snapshot))
        })
      case 'child_added':
        return onChildAdded(this.reference, snapshot => {
          callback(new SnapshotImpl(snapshot))
        })
    }
  }

  once(type: Type, callback: (snapshot: Snapshot) => void) {
    this._log(`${this.url} - ONCE ${type}`)
    switch (type) {
      case 'value':
        return onValue(
          this.reference,
          snapshot => {
            callback(new SnapshotImpl(snapshot))
          },
          { onlyOnce: true },
        )
      case 'child_added':
        return onChildAdded(
          this.reference,
          snapshot => {
            callback(new SnapshotImpl(snapshot))
          },
          { onlyOnce: true },
        )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(value: any): Promise<void> {
    this._log(`${this.url} - SET`, { value })
    return set(this.reference as DatabaseReference, value) // TODO: implement separated interface for Query
  }

  update(value: object): Promise<void> {
    this._log(`${this.url} - UPDATE`, { value })
    return update(this.reference as DatabaseReference, value)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async push(value: any) {
    this._log(`${this.url} - PUSH`, { value })
    const r = await push(this.reference as DatabaseReference, value) // TODO: implement separated interface for Query
    return new ReferenceImpl(this.logger, r, this.url)
  }
}

class SnapshotImpl implements Snapshot {
  snapshot: DataSnapshot
  constructor(snapshot: DataSnapshot) {
    this.snapshot = snapshot
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forEach(eachSnapshot: any): void {
    throw new Error('Method not implemented.')
  }

  val() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.snapshot.val()
  }
}
