import { ErrorSpec } from '../utils/error'
import {
  CommandReducer,
  EventReducer,
  PartyCommand,
  PartyEvent,
  PartyState,
} from './commands'

export const eventReducer = (state: PartyState, events: PartyEvent[]) =>
  events.reduce((prevState, event) => {
    let result: PartyState
    const type = event.type
    switch (type) {
      case 'partyUpdated':
        result = partyUpdated(state, event)
        break
      case 'partyConnected':
        result = partyConnected(state, event)
        break
      case 'partyDisconnected':
        result = partyDisconnected(state, event)
        break
      case 'partyMoved':
        result = partyMoved(state, event)
        break
      case 'partyBlocked':
        result = partyBlocked(state, event)
        break
      default:
        throw {
          name: 'UnexpectedPartyEventType',
          message: `Event type ${type} is not expected for party`,
          target: 'event.type',
          stack: new Error().stack,
        } as ErrorSpec
    }
    result = {
      ...result,
      party: {
        ...result.party,
        version: Number(event.metadata.timestamp),
      },
    }
    return result
  }, state)

const partyUpdated: EventReducer<'partyUpdated'> = (state, event) => ({
  ...state,
  party: {
    ...state.party,
    ...event.payload.party,
  },
})

const partyConnected: EventReducer<'partyConnected'> = (state, event) => ({
  ...state,
  party: {
    ...state.party,
    ...event.payload.party,
  },
})

const partyDisconnected: EventReducer<'partyDisconnected'> = (
  state,
  event,
) => ({
  ...state,
  party: {
    ...state.party,
    ...event.payload.party,
  },
})

const partyMoved: EventReducer<'partyMoved'> = (state, event) => ({
  ...state,
  party: {
    ...state.party,
    ...event.payload.party,
  },
})

const partyBlocked: EventReducer<'partyBlocked'> = (state, event) => ({
  ...state,
  party: {
    ...state.party,
    ...event.payload.party,
  },
})

const metadata = (command: PartyCommand) => ({
  ...command.metadata,
  createdAt: command.metadata?.createdAt ?? '',
  createdBy: command.metadata?.createdBy ?? '',
  timestamp: command.metadata?.timestamp ?? {},
  version: 0,
})

export const commandReducer = (commands: PartyCommand[]) =>
  commands
    .map(command => {
      let events: PartyEvent[]
      const type = command.type
      switch (type) {
        case 'update':
          events = update(command)
          break
        case 'connect':
          events = connect(command)
          break
        case 'disconnect':
          events = disconnect(command)
          break
        case 'move':
          events = move(command)
          break
        case 'block':
          events = block(command)
          break
        default:
          throw {
            name: 'UnexpectedPartyCommandType',
            message: `Command type ${type} is not expected for party`,
            target: 'command.type',
            stack: new Error().stack,
          } as ErrorSpec
      }
      return events
    })
    .flat()

const update: CommandReducer<'update', 'partyUpdated'> = command => {
  const { payload } = command
  return [
    {
      type: 'partyUpdated',
      payload: payload,
      metadata: metadata(command),
    },
  ]
}

const connect: CommandReducer<'connect', 'partyConnected'> = command => {
  const { payload } = command
  return [
    {
      type: 'partyConnected',
      payload: {
        party: {
          ...payload.party,
          status: 'online',
        },
      },
      metadata: metadata(command),
    },
  ]
}

const disconnect: CommandReducer<
  'disconnect',
  'partyDisconnected'
> = command => {
  const { payload } = command
  return [
    {
      type: 'partyDisconnected',
      payload: {
        party: {
          ...payload.party,
          status: 'offline',
        },
      },
      metadata: metadata(command),
    },
  ]
}

const move: CommandReducer<'move', 'partyMoved'> = command => {
  const { payload } = command
  return [
    {
      type: 'partyMoved',
      payload: payload,
      metadata: metadata(command),
    },
  ]
}

const block: CommandReducer<'block', 'partyBlocked'> = command => {
  const { payload } = command
  return [
    {
      type: 'partyBlocked',
      payload: {
        party: {
          ...payload.party,
          status: 'blocked',
        },
      },
      metadata: metadata(command),
    },
  ]
}
