import styled from '@emotion/styled'

interface LayerProps {
  isShowing: boolean
}

export const Layer = styled.div<LayerProps>`
  background-color: ${({ isShowing }) => (isShowing ? '#00B9E7' : '#F95AA0')};
  transition: all 0.2;
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 50;
  left: 50%;
  top: 50%;
  width: 40vw;
  min-height: 40vh;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 16px;
  overflow-y: auto;

  @media (max-width: 600px) {
    width: calc(100vw - 48px);
    left: 50%;
  }
`
