import { ReactElement, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import print from 'print-js'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebase/compat'

import { Party } from '@lib/brz-core-lib-type-ts/party'
import {
  OrderRef,
  OrderState,
  OrderStatus,
} from '@lib/brz-core-lib-type-ts/order'
import { Id, image } from '@lib/brz-core-lib-type-ts/utils'
import { MerchantLayout } from 'src/ui/MerchantLayout/MerchantLayout'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  VStack,
  Grid,
  GridItem,
  ButtonGroup,
  Button,
} from '@chakra-ui/react'
import { getSuffix } from '@lib/brz-core-lib-type-ts/utils/ID'
import {
  MdPhone,
  MdDirectionsRun,
  MdCheckCircleOutline,
  MdOutlineRestaurantMenu,
  MdPrint,
} from 'react-icons/md'
import {
  completePickup,
  completePreparation,
  loadOrders,
  loadParty,
  startPreparation,
  SuborderEntry,
} from './actions'

import { dateAgo } from '@lib/brz-core-lib-type-ts/utils/dateAgo'
import { NewOrderAlert } from 'src/components/NewOrderAlert'

interface Props extends RouteComponentProps {
  merchant?: string
}

const PARAMS: Partial<
  Record<
    OrderStatus,
    {
      statLabel: string
      heading: string
      actionLabel?: string
      actionBg?: string
      actionIcon?: ReactElement
      actionFn?: (
        order: Id<'order'>,
        suborder: Id<'suborder'>,
        merchantKey: string,
      ) => () => void
    }
  >
> = {
  received: {
    statLabel: 'Accept',
    heading: 'Accept',
    actionLabel: 'Start \nPreparation',
    actionBg: 'green.400',
    actionIcon: <MdCheckCircleOutline color="white" size={32} />,
    actionFn: startPreparation,
  },
  waitingPreparation: {
    statLabel: 'Preparing',
    heading: 'Preparing',
    actionLabel: 'Complete \nPreparation',
    actionBg: 'yellow.400',
    actionIcon: <MdOutlineRestaurantMenu color="white" size={32} />,
    actionFn: completePreparation,
  },
  waitingPickup: {
    statLabel: 'Ready',
    heading: 'Ready for Pick up',
    actionLabel: 'Complete \nPickup',
    actionBg: 'pink.400',
    actionIcon: <MdDirectionsRun color="white" size={32} />,
    actionFn: completePickup,
  },
  waitingDelivery: {
    statLabel: 'Delivering',
    heading: 'Delivering',
  },
}

const KEYS = {
  piola_220921: 'party__mock__piola',
  piola_220922: 'party__mock__piola',
  piola_brz_220922: 'party__mock__piola',
  '41_220921': 'party__mock__41-pizza-bakery',
  brz_220921: 'party__mock__beachrunnerz',
}

export const MerchantOrdersPage = ({ merchant, ...route }: Props) => {
  const merchantKey = merchant
  merchant = KEYS[merchant] ?? merchant
  console.log({ merchantKey, merchant })
  const [party, setParty] = useState<Party>()
  const [refs, setRefs] = useState<Record<Id<'order'>, OrderRef>>({})
  const [orders, setOrders] = useState<Record<Id<'order'>, OrderState>>({})
  const [subordersByStatus, setSubordersByStatus] = useState<
    Record<OrderStatus, SuborderEntry[]>
  >({} as Record<OrderStatus, SuborderEntry[]>)
  const [user, loading, error] = useAuthState(auth)

  useEffect(loadParty(user, merchant, setParty), [merchant, setParty, user])
  useEffect(
    loadOrders(
      merchant,
      refs,
      setRefs,
      orders,
      setOrders,
      setSubordersByStatus,
    ),
    [party],
  )

  return (
    <>
      <MerchantLayout {...route} party={party} title="Orders">
        <VStack w="100%" align="center" justify="space-between" pb="6">
          <Grid
            width="100%"
            gap="4"
            gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 1fr 1fr 1fr' }}>
            <GridItem>
              <SuborderStats
                suborders={subordersByStatus['received']}
                status="received"
              />
            </GridItem>

            <GridItem>
              <SuborderStats
                suborders={subordersByStatus['waitingPreparation']}
                status="waitingPreparation"
              />
            </GridItem>

            <GridItem>
              <SuborderStats
                suborders={[
                  ...(subordersByStatus['preparationComplete'] ?? []),
                  ...(subordersByStatus['waitingPickup'] ?? []),
                ]}
                status="waitingPickup"
              />
            </GridItem>

            <GridItem>
              <SuborderStats
                suborders={[
                  ...(subordersByStatus['pickupComplete'] ?? []),
                  ...(subordersByStatus['waitingDelivery'] ?? []),
                ]}
                status="waitingDelivery"
              />
            </GridItem>
          </Grid>

          <Accordion
            defaultIndex={[0, 1, 2, 3, 4, 5]}
            allowMultiple
            borderWidth={0}
            variant="ghost"
            width="100%">
            <SuborderAccordionItem
              suborders={subordersByStatus['received']}
              status="received"
              merchantKey={merchantKey}
            />

            <SuborderAccordionItem
              suborders={subordersByStatus['waitingPreparation']}
              status="waitingPreparation"
              merchantKey={merchantKey}
            />
            <SuborderAccordionItem
              suborders={[
                ...(subordersByStatus['preparationComplete'] ?? []),
                ...(subordersByStatus['waitingPickup'] ?? []),
              ]}
              status="waitingPickup"
              merchantKey={merchantKey}
            />
            <SuborderAccordionItem
              suborders={[
                ...(subordersByStatus['pickupComplete'] ?? []),
                ...(subordersByStatus['waitingDelivery'] ?? []),
              ]}
              status="waitingDelivery"
              merchantKey={merchantKey}
            />
          </Accordion>
        </VStack>
      </MerchantLayout>

      {!!subordersByStatus['received']?.length && (
        <NewOrderAlert>
          <HStack justifyContent="space-between" mb={4}>
            <Heading fontSize="2xl" lineHeight="none" mb="-0.5rem">
              Order #{getSuffix(subordersByStatus['received'][0].order.id)}
            </Heading>
            <Box bg="red.500" p={2} className="brz-timer">
              <Text as="span" fontSize="md" color="white" fontWeight="bold">
                {dateAgo(
                  new Date(subordersByStatus['received'][0].order.version),
                )}
              </Text>
            </Box>
          </HStack>

          {subordersByStatus['received'][0].order?.runner && (
            <HStack justifyContent="space-between" className="brz-runner">
              <Text fontSize="xl">
                <Text as="strong" fontWeight="bold">
                  Runner:
                </Text>{' '}
                {subordersByStatus['received'][0].order.runner.name}
              </Text>
              <Link
                href={`tel:${
                  subordersByStatus['received'][0].order.runner.contacts.find(
                    contact => contact.type.includes('phoneCall'),
                  )?.phone
                }`}
                isExternal>
                <MdPhone size={32} />
              </Link>
            </HStack>
          )}

          {subordersByStatus['received'][0].items.map((item, index) => (
            <HStack
              alignItems="flex-start"
              mt={2}
              pt={index ? 4 : 0}
              mb={2}
              key={index}
              borderTopWidth={index ? 1 : 0}
              borderColor="gray.300">
              <Image
                src={image(item.images)}
                alt="Beach Runnerz"
                htmlHeight={60}
                htmlWidth={60}
              />
              <VStack alignItems="flex-start">
                <Text lineHeight="none" fontWeight="bold" fontSize="lg">
                  {item.name}
                </Text>
                {item.description && (
                  <VStack alignItems="flex-start" pb={2}>
                    {item.description.split('\n').map(line =>
                      line.split(/[,\\:] /g).map((item, index) => (
                        <Text
                          key={index}
                          pl={index ? 4 : 2}
                          lineHeight="none"
                          fontSize={index ? 'sm' : 'md'}>
                          {index ? '• ' : ''}
                          {item}
                        </Text>
                      )),
                    )}
                  </VStack>
                )}
              </VStack>
            </HStack>
          ))}

          <SuborderActions
            suborder={subordersByStatus['received'][0]}
            status={'received'}
            merchantKey={merchantKey}
          />
        </NewOrderAlert>
      )}
    </>
  )
}

export const SuborderStats = ({
  suborders,
  status,
}: {
  suborders?: SuborderEntry[]
  status: OrderStatus
}) => (
  <Box shadow="md" bg="white" px={4} pt={4} pb={2}>
    <Stat>
      <StatLabel fontSize="xl">{PARAMS[status]?.statLabel}</StatLabel>
      <StatNumber fontSize="6xl" lineHeight="shorter" fontWeight="bold">
        {suborders?.length ?? 0}
      </StatNumber>
      <StatHelpText fontSize="lg" lineHeight="shorter">
        orders now
      </StatHelpText>
    </Stat>
  </Box>
)

export const SuborderAccordionItem = ({
  suborders,
  status,
  merchantKey,
}: {
  suborders?: SuborderEntry[]
  status: OrderStatus
  merchantKey: string
}) =>
  suborders &&
  !!suborders.length && (
    <AccordionItem borderWidth={0} mt={6} borderColor="transparent">
      <AccordionButton padding={0} _hover={{ bg: 'transparent' }}>
        <Box flex="1" textAlign="left" padding={0}>
          <Heading fontSize="3xl">
            {PARAMS[status].heading} ({suborders.length})
          </Heading>
        </Box>
        <AccordionIcon fontSize="3xl" />
      </AccordionButton>

      <AccordionPanel px={0} pt={4} pb={8}>
        <Grid
          width="100%"
          gap="4"
          gridTemplateColumns={{
            base: '1fr',
            sm: '1fr 1fr',
            md: '1fr 1fr',
            lg: '1fr 1fr 1fr',
          }}>
          {suborders?.map(suborder => (
            <GridItem
              shadow="md"
              bg="white"
              p={4}
              key={suborder.id}
              position="relative"
              className="brz-order"
              id={`brz-order-${getSuffix(suborder.order.id)}`}>
              <HStack justifyContent="space-between" mb={4}>
                <Heading fontSize="2xl" lineHeight="none" mb="-0.5rem">
                  Order #{getSuffix(suborder.order.id)}
                </Heading>
                <Box bg="red.500" p={2} className="brz-timer">
                  <Text as="span" fontSize="md" color="white" fontWeight="bold">
                    {dateAgo(new Date(suborder.order.version))}
                  </Text>
                </Box>
              </HStack>

              {suborder.order?.runner && (
                <HStack justifyContent="space-between" className="brz-runner">
                  <Text fontSize="xl">
                    <Text as="strong" fontWeight="bold">
                      Runner:
                    </Text>{' '}
                    {suborder.order.runner.name}
                  </Text>
                  <Link
                    href={`tel:${
                      suborder.order.runner.contacts.find(contact =>
                        contact.type.includes('phoneCall'),
                      )?.phone
                    }`}
                    isExternal>
                    <MdPhone size={32} />
                  </Link>
                </HStack>
              )}
              {suborder.items.map((item, index) => (
                <HStack
                  alignItems="flex-start"
                  mt={2}
                  pt={index ? 4 : 0}
                  mb={2}
                  key={index}
                  borderTopWidth={index ? 1 : 0}
                  borderColor="gray.300">
                  <Image
                    src={image(item.images)}
                    alt="Beach Runnerz"
                    htmlHeight={60}
                    htmlWidth={60}
                  />
                  <VStack alignItems="flex-start">
                    <Text lineHeight="none" fontWeight="bold" fontSize="lg">
                      {item.name}
                    </Text>
                    {item.description && (
                      <VStack alignItems="flex-start" pb={2}>
                        {item.description.split('\n').map(line =>
                          line.split(/[,\\:] /g).map((item, index) => (
                            <Text
                              key={index}
                              pl={index ? 4 : 2}
                              lineHeight="none"
                              fontSize={index ? 'sm' : 'md'}>
                              {index ? '• ' : ''}
                              {item}
                            </Text>
                          )),
                        )}
                      </VStack>
                    )}
                  </VStack>
                </HStack>
              ))}
              <SuborderActions
                suborder={suborder}
                status={status}
                merchantKey={merchantKey}
              />
            </GridItem>
          ))}
        </Grid>
      </AccordionPanel>
    </AccordionItem>
  )

export const SuborderActions = ({
  suborder,
  status,
  merchantKey,
}: {
  suborder: SuborderEntry
  status: OrderStatus
  merchantKey: string
}) => {
  const label = PARAMS[status]?.actionLabel
  const bg = PARAMS[status]?.actionBg
  return (
    label && (
      <>
        <Box mt={12} />
        <HStack
          position="absolute"
          bottom={4}
          right={4}
          className="brz-actions">
          <Tooltip label={label}>
            <Button
              fontSize="xs"
              aria-label={label}
              bg={bg}
              _hover={{ bg }}
              color="white"
              leftIcon={PARAMS[status]?.actionIcon}
              onClick={PARAMS[status]?.actionFn(
                suborder.order.id,
                suborder.id,
                merchantKey,
              )}>
              <span
                dangerouslySetInnerHTML={{
                  __html: label?.replace(/\n/g, '<br/>'),
                }}
              />
            </Button>
          </Tooltip>
          <Tooltip label={label}>
            <IconButton
              aria-label="Print order"
              bg="white"
              icon={<MdPrint size={32} />}
              onClick={() => {
                const begin = `
<div class="page-header">
Beach RunnerZ
<h1>Order #${getSuffix(suborder.order.id)}</h1>
<!--
Time ordered: 13:00
Time to finish: 13:30
-->
</div>
<div class="page-footer" style="text-align: center"></div>
<table><thead><tr><td><div class="page-header-space"></div></td></tr></thead><tbody><tr><td>
`
                const end = `
</td></tr></tbody><tfoot><tr><td><div class="page-footer-space"></div></td></tr></tfoot></table>
`

                const content = suborder.items
                  .map(
                    (item, index, { length }) =>
                      `
                      <div class="page">
                      <div class="item-header">${item.description ? '⇩' : ''}	${
                        index + 1
                      } of ${length} items</div>
                      <h3>${item.name}</h3>
                      ${item.description
                        .split('\n')
                        .map(line =>
                          line
                            .split(/[,\\:] /g)
                            .map(
                              (item, index) =>
                                `<div class="${index ? 'value' : 'option'}">${
                                  index ? '• ' : '<strong>'
                                }${item}${index ? '' : '</strong>'}</div>`,
                            )
                            .join(''),
                        )
                        .join('')}
                        ${
                          item.description
                            ? `<div class="item-footer"> ⇧ ${
                                index + 1
                              } of ${length} items</div>`
                            : ''
                        }
                        
                      </div>`,
                  )
                  .join('<hr/>')

                const html = `${begin}${content}${end}`

                print({
                  printable: html,
                  type: 'raw-html',
                  css: '/brz-order-print.css',
                })
              }}
            />
          </Tooltip>
        </HStack>
      </>
    )
  )
}
