import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  VStack,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Tooltip,
  Input,
  Code,
} from '@chakra-ui/react'
import { PartyClient } from '@lib/brz-core-lib-sdk-ts/party'
import {
  MdAdd,
  MdMyLocation,
  MdPerson,
  MdPersonOff,
  MdReplay,
} from 'react-icons/md'
import { DatabaseImpl } from '../../../firebase/modular'
import { SessionImpl } from '../../../firebase/compat'
import {
  EventCallback,
  PartyCommand,
  PartyRefs,
  PartyState,
  RefsCallback,
} from '@lib/brz-core-lib-type-ts/party'
import config from '../../../config'
import { Layout } from 'src/ui/Layout'
import { EnvType } from '@lib/brz-core-lib-type-ts/utils'
import { logger } from '@lib/brz-core-lib-sdk-ts/utils'

logger.setApp({ name: 'brz-partner-app-web-react' })
logger.addOptions({
  console: { threshold: 'warn' },
  sentry: { threshold: 'warn' },
})

const session = new SessionImpl()
const db = new DatabaseImpl(logger)

const client = new PartyClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  partyApi: config('PARTY_API'),
})

let count = 0

const map = new Map<string, string>()

const initialParty = 'party__mock__john-doe'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AdminPartiesScreen = (props: RouteComponentProps) => {
  const [party, setParty] = useState(initialParty)
  const [refs, setRefs] = useState([] as PartyRefs[keyof PartyRefs][])
  const [parties, setParties] = useState({} as Record<string, PartyState>)

  useEffect(() => {
    const eventCallback: EventCallback = state => {
      parties[state.party.id] = state
      setParties({ ...parties })
    }

    const refsCallback: RefsCallback = ref => {
      const newRefs = Object.values(ref)
      newRefs.sort((party1, party2) => party2.version - party1.version)
      setRefs(newRefs)

      Object.keys(ref).forEach(id => {
        if (!map.has(id)) {
          const token2 = client.subscribeParty(id, eventCallback)
          map.set(id, token2)
        }
      })
    }
    const token = client.subscribeParties(refsCallback)

    return () => {
      client.unsubscribe(token)
      Object.values(map).forEach(token => {
        client.unsubscribe(token as string)
      })
    }
  }, [client])

  return (
    <Layout>
      <VStack mt={8} spacing={8} align="flex-start">
        <HStack spacing={4}>
          <Tooltip label="Party Id">
            <Input
              value={party}
              onChange={event => setParty(event.target.value.trim())}
            />
          </Tooltip>
          <IconButton
            aria-label="Create Party"
            title="Create Party"
            icon={<MdAdd />}
            onClick={() => {
              ++count
              client
                .dispatch(party, [UPDATE_PARTY_COMMAND(party)])
                .catch(error => console.error(error))
            }}
          />
        </HStack>

        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>ID</Th>
                <Th>Status</Th>
                <Th>Location</Th>
                <Th>Version</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                /*eslint-disable-next-line @typescript-eslint/no-unsafe-argument*/
                refs.map(({ id, status, location, version }, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td
                      fontSize="xs"
                      title={JSON.stringify(parties[id], undefined, 2)}>
                      {id}
                    </Td>
                    <Td>{status}</Td>
                    <Td fontSize="xs">
                      <Code fontSize="xs" lineHeight="short">
                        {location && (
                          <pre>
                            {location?.latitude},{'\n' + location?.longitude}
                          </pre>
                        )}
                      </Code>
                    </Td>
                    <Td fontSize="xs">{new Date(version).toISOString()}</Td>
                    <Td>
                      <HStack spacing={4}>
                        <IconButton
                          aria-label="Update"
                          title="Update"
                          icon={<MdReplay />}
                          onClick={() => {
                            client
                              .dispatch(id, [UPDATE_PARTY_COMMAND(id, ++count)])
                              .catch(error => console.error(error))
                          }}
                        />
                        <IconButton
                          aria-label="Connect"
                          title="Connect"
                          icon={<MdPerson />}
                          onClick={() => {
                            client
                              .dispatch(id, [
                                CONNECT_PARTY_COMMAND(id, ++count),
                              ])
                              .catch(error => console.error(error))
                          }}
                        />
                        <IconButton
                          aria-label="Disconnect"
                          title="Disconnect"
                          icon={<MdPersonOff />}
                          onClick={() => {
                            client
                              .dispatch(id, [
                                DISCONNECT_PARTY_COMMAND(id, ++count),
                              ])
                              .catch(error => console.error(error))
                          }}
                        />
                        <IconButton
                          aria-label="Move"
                          title="Move"
                          icon={<MdMyLocation />}
                          onClick={() => {
                            client
                              .dispatch(id, [MOVE_PARTY_COMMAND(id, ++count)])
                              .catch(error => console.error(error))
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Layout>
  )
}

type mockFn = (id: string, version?: number) => PartyCommand

const UPDATE_PARTY_COMMAND: mockFn = (id, version?) => ({
  type: 'update',
  payload: {
    party: {
      id,
      name: `Party ${id}${version ? ' v' + version : ''}`,
      description: '',
      contacts: [
        {
          email: `${version ?? '0'}@a.com`,
          type: ['email'],
        },
        {
          phone: `+${version ?? '1'}1234567890`,
          type: [
            'phoneCall',
            'phoneMessage',
            'whatsappCall',
            'whatsappMessage',
          ],
        },
      ],
    },
  },
})

const CONNECT_PARTY_COMMAND: mockFn = (id, version?) => ({
  type: 'connect',
  payload: {
    party: {
      id,
      location: {
        latitude: version ?? 0,
        longitude: version ?? 0,
        address: `v${version ?? 0}`,
        heading: version,
      },
    },
  },
})

const DISCONNECT_PARTY_COMMAND: mockFn = (id, version?) => ({
  type: 'disconnect',
  payload: {
    party: {
      id,
      location: {
        latitude: version ?? 0,
        longitude: version ?? 0,
        address: `v${version ?? 0}`,
        heading: version,
      },
    },
  },
})

const MOVE_PARTY_COMMAND: mockFn = (id, version?) => ({
  type: 'move',
  payload: {
    party: {
      id,
      location: {
        latitude: version ?? 0,
        longitude: version ?? 0,
        address: `v${version ?? 0}`,
        heading: version,
      },
    },
  },
})
