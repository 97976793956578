import { ProductService, ProductByZoneResponse, Product } from '@lib/brz-core-lib-type-ts/product'
import { ApiSpec, HttpClient, Logger, Session } from '../utils'

export class ProductClient extends HttpClient implements ProductService {
  constructor({ productApi, session, logger }: { productApi: string; session?: Session, logger: Logger }) {
    super(productApi, session, logger)
  }
  async getProducts(ids: string[]): Promise<Record<string, Product>> {
    const response = await this.instance.get<ApiSpec<Record<string, Product>>>('', {
      params: { id: ids.join(',') },
    })
    return response.data.value
  }
  async findProductByZone(zone?: string, phoneNumber?: string): Promise<ProductByZoneResponse> {
    const response = await this.instance.get<ApiSpec<ProductByZoneResponse>>('', {
      params: { zone, phoneNumber },
    })
    return response.data.value
  }
}
