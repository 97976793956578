import { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import { PartyClient } from '@lib/brz-core-lib-sdk-ts/party'
import { DatabaseImpl } from '../../../firebase/modular'
import { SessionImpl } from '../../../firebase/compat'
import config from '../../../config'
import { Layout } from 'src/ui/Layout'
import { EnvType, getSuffix, Id } from '@lib/brz-core-lib-type-ts/utils'
import { logger } from '@lib/brz-core-lib-sdk-ts/utils'
import { Order, OrderInput } from '@lib/brz-core-lib-type-ts/price'
import { PriceClient } from '@lib/brz-core-lib-sdk-ts'
import { sleep } from '@lib/brz-core-lib-sdk-ts/utils/sleep'
import { timestamp } from '@lib/brz-core-lib-type-ts/utils/timestamp'

logger.setApp({ name: 'brz-partner-app-web-react' })
logger.addOptions({
  console: { threshold: 'warn' },
  sentry: { threshold: 'warn' },
})

const session = new SessionImpl()
const db = new DatabaseImpl(logger)

const partyClient = new PartyClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  partyApi: config('PARTY_API'),
})

const priceClient = new PriceClient({
  priceApi: config('PRICE_API'),
  logger,
  session,
})

const input = [
  // {
  //   id: 'order__brz_guest_app_mobile__20220904_J4MJUMVH',
  //   merchant: 'Piola',
  //   items: [
  //     {
  //       quantity: 1,
  //       product: 'product__mock__latte',
  //       options: ['value__mock__latte.preference.almond-milk'],
  //     },
  //   ],
  // },

  {
    id: 'order__brz_guest_app_mobile__20220904_HAGYKH4X',
    merchant: 'Piola',
    items: [
      {
        quantity: 1,
        product: 'product__mock__panna-flat-water-1l',
      },
      {
        quantity: 1,
        product: 'product__mock__limonata-san-pellegrino',
      },
    ],
  },
  {
    id: 'order__brz_guest_app_mobile__20220904_AQPQVNVG',
    merchant: '41 Pizza & Bakery',
    items: [
      {
        quantity: 1,
        product: 'product__mock__blueberry-strawberry-shake',
        options: [
          'value__mock__blueberry-strawberry-shake.preferences.with-water-v-gf',
        ],
      },
      {
        quantity: 1,
        product: 'product__mock__seltzer-water',
        //options: ['value__mock__seltzer-water.preferences.passion-fruit'],
      },
      {
        quantity: 1,
        product: 'product__mock__seltzer-water',
        //        options: ['value__mock__seltzer-water.preferences.cranberry'],
      },
      {
        quantity: 1,
        product: 'product__mock__fiji-water',
      },
    ],
  },
  {
    id: 'order__brz_guest_app_mobile__20220904_AMALYVDR',
    merchant: '41 Pizza & Bakery',
    items: [
      {
        quantity: 1,
        product: 'product__mock__fiji-water',
      },
    ],
  },
  // {
  //   id: 'order__brz_guest_app_mobile__20220904_AMALYVDR',
  //   merchant: 'BRZ',
  //   items: [
  //     {
  //       quantity: 1,
  //       product: 'product__mock__brz-beach-towel',
  //     },
  //     {
  //       quantity: 1,
  //       product: 'product__mock__wet-ones',
  //     },
  //   ],
  // },
  {
    id: 'order__brz_guest_app_mobile__20220904_PX7PT8DX',
    merchant: 'Piola',
    items: [
      {
        quantity: 1,
        product: 'product__mock__pepperoni-pizza-pie',
        options: [
          'value__mock__pepperoni-pizza-pie.crust.regular-crust',
          'value__mock__pepperoni-pizza-pie.cheese.shredded-mozzarella-cheese',
        ],
      },
      {
        quantity: 1,
        product: 'product__mock__sprite',
      },
      {
        quantity: 2,
        product: 'product__mock__coke',
      },
      {
        quantity: 1,
        product: 'product__mock__fanta',
      },
    ],
  },
  // {
  //   id: 'order__brz_guest_app_mobile__20220904_HJ76WUJR',
  //   merchant: 'BRZ',
  //   items: [
  //     {
  //       quantity: 1,
  //       product: 'product__mock__brz-beach-towel',
  //     },
  //   ],
  // },
  {
    id: 'order__brz_guest_app_mobile__20220904_89WDFFA7',
    merchant: 'Piola',
    items: [
      {
        quantity: 1,
        product: 'product__mock__ortomisto-pizza-combo',
        options: [
          'value__mock__ortomisto-pizza-combo.crust.regular-crust',
          'value__mock__ortomisto-pizza-combo.drink.coke',
          'value__mock__ortomisto-pizza-combo.cheese.shredded-mozzarella-cheese',
          'value__mock__ortomisto-pizza-combo.side.garlic-knots',
        ],
        addons: [
          'value__mock__bourekas.preference.spinach',
          'value__mock__bourekas.preference.mushroom',
        ],
      },
    ],
  },
  {
    id: 'order__brz_guest_app_mobile__20220904_ENF769AW',
    merchant: '41 Pizza & Bakery',
    items: [
      {
        quantity: 1,
        product: 'product__mock__bourekas',
        addons: [
          'value__mock__bourekas.preference.spinach',
          'value__mock__bourekas.preference.mushroom',
        ],
      },
      {
        quantity: 1,
        product: 'product__mock__house-salad',
        options: [
          'value__mock__house-salad.dressing.raspberry-vinaigrette-dressing-v-gf',
        ],
        addons: [
          'value__mock__house-salad.enjoy-something-extra.large-onion-rings-vg',
        ],
      },
    ],
  },
] as (OrderInput & { merchant: Id<'party'> })[]

const payments = {
  // order__brz_guest_app_mobile__20220904_J4MJUMVH: {
  //   date: 'Sep 3, 11:39 PM',
  //   amount: 15.09,
  //   fee: 0.74,
  //   net: 14.35,
  //   card: '•••• 7236',
  //   payment: 'pi_3Le9fXFcadCkNN1d0OCmB5hb',
  //   guest: 'user__mock__ariane-gonzaga',
  //   order: 'order__brz_guest_app_mobile__20220904_J4MJUMVH',
  // },
  order__brz_guest_app_mobile__20220904_HAGYKH4X: {
    date: 'Sep 4, 12:30 PM',
    amount: 23.2,
    fee: 0.97,
    net: 22.23,
    card: '•••• 9721',
    payment: 'pi_3LeLgwFcadCkNN1d1IS9C4Tr',
    guest: 'user__mock__josmanny-horta',
    order: 'order__brz_guest_app_mobile__20220904_HAGYKH4X',
  },
  order__brz_guest_app_mobile__20220904_AQPQVNVG: {
    date: 'Sep 4, 12:35 PM',
    amount: 42.51,
    fee: 1.53,
    net: 40.98,
    card: '•••• 9721',
    payment: 'pi_3LeLmMFcadCkNN1d0xZ6hjg1',
    guest: 'user__mock__francia-groman',
    order: 'order__brz_guest_app_mobile__20220904_AQPQVNVG',
  },
  order__brz_guest_app_mobile__20220904_AMALYVDR: {
    date: 'Sep 4, 12:54 PM',
    amount: 42.57,
    fee: 1.53,
    net: 41.04,
    card: '•••• 9721',
    payment: 'pi_3LeM5EFcadCkNN1d0CaQbalI',
    guest: 'user__mock__francia-groman',
    order: 'order__brz_guest_app_mobile__20220904_AMALYVDR',
  },
  order__brz_guest_app_mobile__20220904_PX7PT8DX: {
    date: 'Sep 4, 1:19 PM',
    amount: 64.44,
    fee: 2.17,
    net: 62.27,
    card: '•••• 5817',
    payment: 'pi_3LeMS6FcadCkNN1d0JJ0ct5T',
    guest: 'user__mock__josmanny-horta',
    order: 'order__brz_guest_app_mobile__20220904_PX7PT8DX',
  },
  order__brz_guest_app_mobile__20220904_HJ76WUJR: {
    date: 'Sep 4, 2:50 PM',
    amount: 32.25,
    fee: 1.24,
    net: 31.01,
    card: '•••• 0899',
    payment: 'pi_3LeNsDFcadCkNN1d1itH0kO3',
    guest: 'user__mock__josmanny-horta',
    order: 'order__brz_guest_app_mobile__20220904_HJ76WUJR',
  },
  order__brz_guest_app_mobile__20220904_89WDFFA7: {
    date: 'Sep 4, 3:24 PM',
    amount: 58.04,
    fee: 1.98,
    net: 56.06,
    card: '•••• 1343',
    payment: 'pi_3LeOPaFcadCkNN1d1dxHCXqU',
    guest: 'party__firebase__W90ThhaANeUqgiS6XW9tnKDAh7p2',
    order: 'order__brz_guest_app_mobile__20220904_89WDFFA7',
  },
  order__brz_guest_app_mobile__20220904_ENF769AW: {
    date: 'Sep 4, 3:35 PM',
    amount: 76.66,
    fee: 2.52,
    net: 74.14,
    card: '•••• 9721',
    payment: 'pi_3LeObOFcadCkNN1d1wBvYxbN',
    guest: 'user__mock__francia-groman',
    order: 'order__brz_guest_app_mobile__20220904_ENF769AW',
  },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AdminPaymentsScreen = (props: RouteComponentProps) => {
  const [orders, setOrders] = useState<
    Record<string, Order & { merchant: Id<'party'> }>
  >({})
  useEffect(() => {
    const init = async () => {
      setOrders(
        Object.assign(
          {},
          ...(await Promise.all(
            input.map(async (input, index) => {
              console.log(
                `>= ${index}: ${timestamp()} - ${input?.id}-${input?.merchant}`,
              )
              await sleep(index * 1000)

              try {
                const order = {
                  ...(await priceClient.calculateOrderPrice(input)),
                  merchant: input?.merchant,
                }

                console.log(`<= ${index}: ${timestamp()} - ${order?.id}`)
                return {
                  [`${input?.id}-${input?.merchant}`]: order,
                }
              } catch (error) {
                console.error(
                  `!= ${index}: ${timestamp()} - ${input?.id}`,
                  error,
                )
              }
            }),
          )),
        ) as Record<string, Order & { merchant: Id<'party'> }>,
      )
    }

    void init()
  }, [])
  return (
    <Layout>
      <VStack mt={8} spacing={8} align="flex-start">
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Date</Th>
                <Th>Order</Th>
                <Th>Stripe</Th>
                <Th>Merchant</Th>
                <Th>Guest</Th>
                <Th>Card</Th>
                <Th textAlign="center">
                  Stripe <br />
                  Amount
                </Th>
                <Th textAlign="center">
                  Stripe <br />
                  Fee
                </Th>
                <Th textAlign="center">
                  Stripe <br />
                  Net
                </Th>
                <Th textAlign="center">
                  Merchant <br />
                  Order Total
                </Th>
                <Th textAlign="center">
                  Merchant <br />
                  Subtotal
                </Th>
                <Th textAlign="center">
                  Merchant <br />
                  Fee Total
                </Th>
                <Th textAlign="center">
                  Delivery <br />
                  Tip + Fee
                </Th>
                {/* <Th textAlign="center">
                  Platform <br />
                  Order + Tax
                </Th> */}
                <Th textAlign="center">
                  Guest <br />
                  Order + Tax
                </Th>
                <Th textAlign="center">
                  Guest <br />
                  Subtotal
                </Th>
                <Th textAlign="center">
                  Guest <br />
                  Fees Total
                </Th>
                <Th textAlign="center">
                  Guest <br />
                  Order Total
                </Th>
                <Th textAlign="center">
                  Guest <br />
                  Tax Total
                </Th>
                {/* <Th textAlign="center">
                  Platform <br />
                  Subtotal
                </Th>
                <Th textAlign="center">
                  Platform <br />
                  Fees Total
                </Th>
                <Th textAlign="center">
                  Platform <br />
                  Order Total
                </Th>
                <Th textAlign="center">
                  Platform <br />
                  Tax Total
                </Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {
                /*eslint-disable-next-line @typescript-eslint/no-unsafe-argument*/
                Object.values(orders).map(
                  ({ id, totals, merchant, items }, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td fontSize="xs">{payments[id]?.date}</Td>
                      <Td
                        fontSize="xs"
                        title={items
                          .map(item =>
                            item.price.missing && item.price.missing.length
                              ? `missing: ${item.price.missing.join(', ')}`
                              : undefined,
                          )
                          .filter(item => item)
                          .join('\n')}>
                        #{getSuffix(id)}
                      </Td>
                      <Td fontSize="xs">{payments[id]?.payment}</Td>
                      <Td fontSize="xs">{merchant}</Td>
                      <Td fontSize="xs">
                        {getSuffix((payments[id]?.guest as string) ?? '')}
                      </Td>
                      <Td fontSize="xs">{payments[id]?.card}</Td>
                      <Td
                        textAlign="center"
                        fontWeight="bold"
                        bgColor="purple.100">
                        {money(payments[id]?.amount)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="purple.100">
                        {money(payments[id]?.fee)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="purple.100">
                        {money(payments[id]?.net)}
                      </Td>
                      <Td
                        textAlign="center"
                        fontWeight="bold"
                        bgColor="orange.100"
                        title={items
                          .map(
                            item =>
                              `${item.quantity}x ${getSuffix(
                                item.product,
                              )} ${item.price.merchant.totalPrice?.toFixed(
                                2,
                              )} (${[
                                ...(item.options?.map(
                                  value =>
                                    getSuffix(value) +
                                    (value && item.price.missing.includes(value)
                                      ? '_x'
                                      : ''),
                                ) ?? []),
                                ...(item.addons?.map(
                                  value =>
                                    getSuffix(value) +
                                    (value && item.price.missing.includes(value)
                                      ? '_x'
                                      : ''),
                                ) ?? []),
                              ].join(', ')})`,
                          )
                          .join('\n')}>
                        {money(totals.merchant.orderTotal)}
                      </Td>
                      <Td
                        textAlign="center"
                        fontSize="xs"
                        bgColor="orange.100"
                        title={items
                          .map(
                            item =>
                              `${item.quantity}x ${getSuffix(
                                item.product,
                              )} $${item.price.merchant.totalPrice?.toFixed(
                                2,
                              )} (${[
                                ...(item.options?.map(item =>
                                  getSuffix(item),
                                ) ?? []),
                                ...(item.addons?.map(item => getSuffix(item)) ??
                                  []),
                              ].join(', ')})`,
                          )
                          .join('\n')}>
                        {money(totals.merchant.subtotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="orange.100">
                        {money(totals.merchant.feesTotal)}
                      </Td>
                      <Td
                        textAlign="center"
                        fontWeight="bold"
                        bgColor="blue.100">
                        {money(totals.delivery.orderTotal)}
                      </Td>
                      {/* <Td
                        textAlign="center"
                        fontWeight="bold"
                        bgColor="green.100">
                        {money(totals.platform.orderTotal)}
                      </Td> */}
                      <Td
                        textAlign="center"
                        fontWeight="bold"
                        bgColor="pink.100"
                        title={items
                          .map(
                            item =>
                              `${item.quantity}x ${getSuffix(item.product)}${
                                item.price.missing.includes(item.product)
                                  ? '_x'
                                  : ''
                              } $${item.price.guest.totalPrice} (${[
                                ...(item.options?.map(
                                  value =>
                                    getSuffix(value) +
                                    (value && item.price.missing.includes(value)
                                      ? '_x'
                                      : ''),
                                ) ?? []),
                                ...(item.addons?.map(
                                  value =>
                                    getSuffix(value) +
                                    (value && item.price.missing.includes(value)
                                      ? '_x'
                                      : ''),
                                ) ?? []),
                              ].join(', ')})`,
                          )
                          .join('\n')}>
                        {money(totals.guest.orderAndTaxTotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="pink.100">
                        {money(totals.guest.subtotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="pink.100">
                        {money(totals.guest.feesTotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="pink.100">
                        {money(totals.guest.orderTotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="pink.100">
                        {money(totals.guest.taxTotal)}
                      </Td>
                      {/* <Td textAlign="center" fontSize="xs" bgColor="green.100">
                        {money(totals.platform.subtotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="green.100">
                        {money(totals.platform.feesTotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="green.100">
                        {money(totals.platform.orderTotal)}
                      </Td>
                      <Td textAlign="center" fontSize="xs" bgColor="green.100">
                        {money(totals.platform.taxTotal)}
                      </Td> */}
                    </Tr>
                  ),
                )
              }
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Layout>
  )
}

const money = (value?: unknown) =>
  value && typeof value === 'number'
    ? `${value < 0 ? '-' : ''}$${Math.abs(value).toFixed(2)}`
    : undefined
