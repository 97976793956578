import { ReactNode } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  ButtonGroup,
  Heading,
  HStack,
  Text,
  VStack,
  Box,
  Flex,
} from '@chakra-ui/react'
import { Party } from 'src/lib/brz-core-lib-type-ts/party'
import { Header } from 'src/components/Header'

interface Props extends RouteComponentProps {
  party?: Party
  title: string
  description?: string
  actions?: ReactNode | ReactNode[]
  children?: ReactNode
}

export const MerchantLayout = ({
  party,
  title,
  description,
  actions,
  children,
  navigate,
  path,
}: Props) => {
  return (
    <>
      <VStack flex={1} minH="100vh" bg="gray.100" height="100%">
        <Header navigate={navigate} path={path} party={party} />

        <Flex
          w="100%"
          mx="auto"
          align="center"
          justifyContent="center"
          px="6"
          py="6"
          pb="2">
          <HStack justify="space-between" w="100%" maxWidth={1480} flex={1}>
            <VStack alignItems="flex-start">
              <Heading fontSize="5xl">{title}</Heading>
              {description && <Text>{description}</Text>}
            </VStack>
            <ButtonGroup variant="outline">{actions}</ButtonGroup>
          </HStack>
        </Flex>

        <Flex w="100%" mx="auto" px="6" align="center">
          <Flex w="100%" mx="auto" maxWidth={1480}>
            {children}
          </Flex>
        </Flex>
      </VStack>
    </>
  )
}
