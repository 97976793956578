import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import {
  Dedupe,
  ExtraErrorData,
  Offline,
  CaptureConsole,
} from '@sentry/integrations'

import config from '../config'

Sentry.init({
  dsn: 'https://01489fb68c7e443d92af7053d154a8c2@o1332313.ingest.sentry.io/6727365',
  integrations: [
    new BrowserTracing(),
    new Dedupe(),
    new ExtraErrorData(),
    new Offline(),
    // new CaptureConsole(),
  ],
  tracesSampleRate: 1.0,
  //debug: true,
  environment: config('ENV'),
  autoSessionTracking: true,
  release: config('VERSION'),
})

export const init = () => {}
