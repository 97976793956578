import {
  UserService,
  User,
  UserSessionInput,
  UserSession,
} from '@lib/brz-core-lib-type-ts/user'
import { EnvType, last } from '@lib/brz-core-lib-type-ts/utils'
import {
  ApiSpec,
  Database,
  HttpClient,
  Logger,
  Session,
  unique,
} from '../utils'

export class UserClient extends HttpClient implements UserService {
  prefix: EnvType
  logger: Logger
  db: Database

  constructor({
    prefix,
    db,
    userApi,
    session,
    logger,
  }: {
    prefix: EnvType
    db: Database
    userApi: string
    session: Session
    logger: Logger
  }) {
    super(userApi, session, logger)
    this.prefix = prefix
    this.db = db
    this.logger = logger
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ld(message: string, context?: Record<string, unknown>, error?: any) {
    this.logger?.d(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  li(message: string, context?: Record<string, unknown>, error?: any) {
    this.logger?.i(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  le(message: string, context?: Record<string, unknown>, error?: any) {
    this.logger?.e(message, { context, error })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lw(message: string, context: Record<string, unknown>, error?: any) {
    this.logger?.w(message, { context, error })
  }

  getRepos(path: string) {
    const result = [
      this.db.ref([this.prefix, path].filter(item => item).join('/')),
    ]

    if (!this.prefix) {
      this.lw('Prefix not provided!', {})
      throw new Error('Prefix not provided!')
    }
    // if (this.prefix === 'prd') {
    //   result.push(this.db.ref(path))
    // }

    return result
  }

  async saveUserSession(session: UserSessionInput): Promise<User> {
    try {
      const response = await this.instance.post<ApiSpec<User>>(
        'sessions',
        session,
      )

      const user = response.data.value

      const parties = unique(
        response.data.value.profiles
          .map(profile =>
            (!session.partyType || session.partyType === 'guest') &&
            profile.type === 'guest'
              ? [profile.guest]
              : (!session.partyType || session.partyType === 'runner') &&
                profile.type === 'runner'
              ? [profile.runner]
              : (!session.partyType || session.partyType === 'merchant') &&
                profile.type === 'merchant'
              ? profile.merchants
              : [],
          )
          .flat()
          .filter(party => party && party.trim()),
      )

      await Promise.all([
        parties.map(party =>
          this.getRepos(
            `parties/sessions/${party}/${session.partyType}/${session.deviceToken}`,
          ).map(repo => repo.set(session)),
        ),
      ])

      //

      return Promise.resolve(response.data.value)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getSessions(): Promise<UserSession[]> {
    const value = await (
      await last(this.getRepos(`parties/sessions`)).get()
    ).val()

    const sessions = Object.entries(value)
      .map(([partyId, value]) =>
        Object.entries(value).map(([partyType, value]) =>
          typeof value !== 'object'
            ? {}
            : Object.entries(value).map(([_, value]) =>
                typeof value !== 'object'
                  ? undefined
                  : {
                      partyId,
                      ...(['guest', 'runner'].includes(partyType)
                        ? { partyType }
                        : { id: partyType }),
                      ...(value as any),
                    },
              ),
        ),
      )
      .flat()
      .flat()
      .filter(item => item && (item as any).partyType)

    return sessions as UserSession[]
  }

  async getUserById(id: string): Promise<User> {
    const response = await this.instance.get<ApiSpec<User>>('', {
      params: { id },
    })
    return response.data.value
  }

  async getUserByContact(contact: string): Promise<User> {
    const response = await this.instance.get<ApiSpec<User>>('', {
      params: { contact },
    })
    return response.data.value
  }

  async deleteUserById(id: string): Promise<boolean> {
    const response = await this.instance.delete<ApiSpec<boolean>>('', {
      params: { id },
    })
    return response.data.value
  }
}
