export type ErrorSpec = {
  name: string
  message: string
  target?: string // location of the field in the error, either `header`, `query`, `path`, or `body`. If this field is not present, the default value is `body`.
  details?: ErrorDetailSpec[]
  cause?: Error
  stack?: string
  trace?: TraceSpec
}

export type TraceSpec = {
  parent_id?: string
  trace_id?: string
  span_id?: string
}

export type ErrorDetailSpec = {
  code: string
  message: string
  value: string
  target: string // JSON Pointer to the field in error if in body, else name of the path parameter or query parameter
}

export const convertError = (
  error: unknown,
  trace?: TraceSpec,
): ErrorSpec | undefined =>
  error ? new ErrorBuilder().setError(error).setTrace(trace).get() : undefined

export const errorMessage = (error: unknown) =>
  typeof error === 'object' //
    ? toString((error as Record<string, unknown>).message)
    : toString(error)

function toString(value: unknown) {
  if (value ?? false) {
    if (typeof value === 'string') return value
    if (typeof value !== 'object' && typeof value !== 'function')
      return String(value)
    try {
      return JSON.stringify(value)
    } catch {
      return String(value)
    }
  }
  return ''
}

class ErrorBuilder {
  object: ErrorSpec
  constructor() {
    this.object = { name: '', message: '' }
  }
  setError(error: unknown) {
    if (typeof error === 'object') {
      const record = error as Record<string, unknown>
      this.set('name', toString(record.name))
      this.set('message', toString(record.message))
      this.set('stack', toString(record.stack))
    } else {
      this.set('name', 'GenericError')
      this.set('message', toString(error))
    }
    return this
  }
  setTrace(trace: TraceSpec | undefined) {
    this.object.trace = trace
    return this
  }

  set<K extends keyof ErrorSpec>(key: K, value: ErrorSpec[K]) {
    if (value) {
      this.object[key] = value
    } else {
      delete this.object[key]
    }
    return this
  }
  get() {
    return this.object
  }
}
