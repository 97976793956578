import { CommandSpec, OrderService } from '@lib/brz-core-lib-type-ts/order'
import { PartyService } from '@lib/brz-core-lib-type-ts/party'
import { Product, ProductService } from '@lib/brz-core-lib-type-ts/product'
import { ID, Id } from '@lib/brz-core-lib-type-ts/utils'

const randomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const randomItem = <T>(array: T[]) => array[randomInt(0, array.length - 1)]

const randomProduct = (products: Product[]) => randomItem(products)
const productMerchantMap: Record<Id<'party'>, Product[]> = {}

export const saveSubmitAssignRunner =
  (
    partyService: PartyService,
    productService: ProductService,
    orderService: OrderService,
  ) =>
  () => {
    const init = async () => {
      const guests = await partyService.findPartyByType('guest')
      const guest = randomItem(guests)
      // console.log('saveSubmitAssignRunner', guests)
      const runners = await partyService.findPartyByType('runner')
      const runner = randomItem(runners)

      const { products } = await productService.findProductByZone()

      products.forEach(product => {
        product.merchants.forEach(merchant => {
          let list = productMerchantMap[merchant]
          if (!list) {
            list = []
            productMerchantMap[merchant] = list
          }
          list.push(product)
        })
      })
      const backlog = Object.values(productMerchantMap).map(products =>
        Array(randomInt(3, Math.min(5, products.length)))
          .fill(0)
          .map(() => {
            const product = randomProduct(products)
            return {
              product: product.id,
              options: Object.values(product.options ?? {})
                .map(option => Object.keys(option.values ?? {})[0])
                .flat(),
              addons: Object.values(product.addons ?? {})
                .map(option => Object.keys(option.values ?? {}))
                .flat(),
            }
          }),
      )
      const id = ID('order', 'mock')
      // console.log(id)
      await orderService.dispatch(id, [
        saveOrder(id, guest?.id, backlog),
        submitOrder(id),
        assignRunner(id, runner?.id),
      ])
    }
    init().catch(console.error) // TODO: error handling
  }

export const genProducts = async (productService: ProductService) => {
  const { products } = await productService.findProductByZone()

  products.forEach(product => {
    product.merchants.forEach(merchant => {
      let list = productMerchantMap[merchant]
      if (!list) {
        list = []
        productMerchantMap[merchant] = list
      }
      list.push(product)
    })
  })
  const backlog = Object.values(productMerchantMap).map(products =>
    Array(randomInt(3, Math.min(5, products.length)))
      .fill(0)
      .map(() => {
        const product = randomProduct(products)
        return {
          product: product.id,
          options: Object.values(product.options ?? {})
            .map(option => Object.keys(option.values ?? {})[0])
            .flat(),
          addons: Object.values(product.addons ?? {})
            .map(option => Object.keys(option.values ?? {}))
            .flat(),
        }
      }),
  )
  return backlog
}

export const genGuest = async (partyService: PartyService) => {
  const guests = await partyService.findPartyByType('guest')
  const guest = randomItem(guests)
  return guest.id
}

export const dispatchSaveOrder = async (
  id: Id<'order'> | undefined,
  guest: Id<'party'> | undefined,
  orderService: OrderService,
  partyService: PartyService,
  productService: ProductService,
) => {
  id = id ?? ID('order', 'mock')
  orderService.dispatch(id, [
    saveOrder(
      id,
      guest ?? (await genGuest(partyService)),
      await genProducts(productService),
    ),
  ])
}

export const genSaveOrder = async (
  partyService: PartyService,
  productService: ProductService,
) =>
  saveOrder(
    ID('order', 'mock'),
    await genGuest(partyService),
    await genProducts(productService),
  )

export const saveOrder = (
  id: Id<'order'>,
  guest: Id<'party'>,
  products: {
    product: Id<'product'>
    options: Id<'value'>[]
    addons: Id<'value'>[]
  }[][],
) =>
  ({
    type: 'saveOrder',
    payload: {
      order: {
        id,
        guest: {
          id: guest,
          location: {
            latitude: 25.76923528587253,
            longitude: -80.13046056049492,
            address: '1st St. LGP, Zone 1, South Beach',
            heading: 90,
          },
        },
        location: {
          latitude: 25.76923528587253,
          longitude: -80.13046056049492,
          address: '1st St. LGP, Zone 1, South Beach',
          heading: 90,
        },
        items: products
          .map((products, suborder) =>
            products.map((product, item) => ({
              id: `item-${id}-${suborder + 1}-${item + 1}`,
              quantity: item + 1,
              product: product.product,
              options: product.options,
              addons: product.addons,
            })),
          )
          .flat(),
      },
    },
  } as CommandSpec<'saveOrder'>)

export const submitOrder = (id: Id<'order'>) =>
  ({
    type: 'submitOrder',
    payload: {
      order: {
        id,
      },
    },
  } as CommandSpec<'submitOrder'>)

export const assignRunner = (id: Id<'order'>, runner: Id<'party'>) =>
  ({
    type: 'assignRunner',
    payload: {
      order: {
        id,
        runner: {
          id: runner,
        },
      },
    },
  } as CommandSpec<'assignRunner'>)

export const completeDelivery = (id: Id<'order'>) =>
  ({
    type: 'completeDelivery',
    payload: {
      order: {
        id,
      },
    },
  } as CommandSpec<'completeDelivery'>)
