import { Dispatch, SetStateAction } from 'react'

import {
  Suborder,
  Order,
  OrderRef,
  OrderState,
  OrderStatus,
  OrderCommand,
} from '@lib/brz-core-lib-type-ts/order'
import { PartyClient } from '@lib/brz-core-lib-sdk-ts/party'
import { OrderClient } from '@lib/brz-core-lib-sdk-ts/order'
import { ProductClient } from '@lib/brz-core-lib-sdk-ts/product'
import { DatabaseImpl } from '../../../firebase/modular'
import { User, SessionImpl } from '../../../firebase/compat'
import config from '../../../config'
import { EnvType, Id } from '@lib/brz-core-lib-type-ts/utils'
import { Party } from '@lib/brz-core-lib-type-ts/party'
import {
  completeDelivery,
  saveSubmitAssignRunner,
} from '@lib/brz-core-lib-sdk-ts/test'
import { logger } from '@lib/brz-core-lib-sdk-ts/utils'

export type SuborderEntry = Suborder & { order: Order }

logger.setApp({ name: 'brz-partner-app-web-react' })
logger.addOptions({
  console: { threshold: 'warn' },
  sentry: { threshold: 'warn' },
})

const session = new SessionImpl()
const db = new DatabaseImpl(logger)

const orderClient = new OrderClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  productApi: config('PRODUCT_API'),
  partyApi: config('PARTY_API'),
})

const partyClient = new PartyClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  partyApi: config('PARTY_API'),
})
const productClient = new ProductClient({
  logger,
  session,
  productApi: config('PRODUCT_API'),
})

const map = new Map<string, string[]>()

export const completeDeliveryFn =
  (subordersByStatus: Record<OrderStatus, SuborderEntry[]>) => () => {
    ;[...Object.values(subordersByStatus).flat()].forEach(suborder => {
      const id = suborder?.order?.id
      void orderClient.dispatch(id, [completeDelivery(id) as OrderCommand])
    })
  }

export const saveSubmitAssignRunnerFn = saveSubmitAssignRunner(
  partyClient,
  productClient,
  orderClient,
)

export const startPreparation =
  (order: Id<'order'>, suborder: Id<'suborder'>, key: string) => () => {
    void orderClient.dispatch(order, [
      {
        type: 'startPreparation',
        payload: { order: { id: order, suborder: suborder } },
        metadata: { createdBy: key },
      },
    ])
  }

export const completePreparation =
  (order: Id<'order'>, suborder: Id<'suborder'>, key: string) => () => {
    void orderClient.dispatch(order, [
      {
        type: 'completePreparation',
        payload: { order: { id: order, suborder } },
        metadata: { createdBy: key },
      },
    ])
  }

export const completePickup =
  (order: Id<'order'>, suborder: Id<'suborder'>, key: string) => () => {
    void orderClient.dispatch(order, [
      {
        type: 'completePickup',
        payload: { order: { id: order, suborder } },
        metadata: { createdBy: key },
      },
    ])
  }

export const loadParty =
  (
    user: User,
    merchant: Id<'party'>,
    setParty: Dispatch<SetStateAction<Party>>,
  ) =>
  () => {
    const init = async () => {
      user &&
        typeof setParty === 'function' &&
        setParty(await getParty(merchant))
    }
    init().catch(console.error) // TODO: error handling
    return () => {}
  }

export const getParty = async (merchant: Id<'party'>) =>
  (await partyClient.getParties([merchant]))[merchant]

type OrderRefs = Record<Id<'order'>, OrderRef>
type Orders = Record<Id<'order'>, OrderState>
type SubordersByStatus = Record<OrderStatus, SuborderEntry[]>

const eventCallback =
  (
    merchant: Id<'party'>,
    orders: Orders,
    setOrders: Dispatch<SetStateAction<Orders>>,
    setSubordersByStatus: Dispatch<SetStateAction<SubordersByStatus>>,
  ) =>
  ({ order }: OrderState) => {
    // console.log('eventCallback', { order })

    orders[order.id] = {
      order: {
        ...order,
        suborders: order?.suborders?.filter(
          suborder => suborder?.merchant?.id === merchant,
        ),
      },
    }
    setOrders({ ...orders })

    const subordersByStatus = {} as SubordersByStatus
    Object.values(orders).forEach(({ order }) => {
      order.suborders?.forEach(suborder => {
        const status = suborder?.status
        let entry = subordersByStatus[status]
        if (!entry) {
          entry = []
          subordersByStatus[status] = entry
        }
        if (suborder.merchant.id === merchant) {
          entry.push({ ...suborder, order: order })
        }
      })
    })
    setSubordersByStatus(subordersByStatus)
  }

const refCallback =
  (
    merchant: Id<'party'>,
    refs: OrderRefs,
    setRefs: Dispatch<SetStateAction<OrderRefs>>,
    orders: Orders,
    setOrders: Dispatch<SetStateAction<Orders>>,
    setSubordersByStatus: Dispatch<SetStateAction<SubordersByStatus>>,
  ) =>
  ref => {
    refs[ref.id] = ref

    setRefs({ ...refs })

    Object.keys(refs).forEach(id => {
      const key = `${merchant}:${id}`
      if (!map.has(key)) {
        const token = orderClient.subscribeOrder(
          merchant,
          id,
          eventCallback(merchant, orders, setOrders, setSubordersByStatus),
        )
        map.set(key, token)
      }
    })
    //console.log('refCallback', { refs })
  }

export const loadOrders =
  (
    merchant: Id<'party'>,
    refs: OrderRefs,
    setRefs: Dispatch<SetStateAction<OrderRefs>>,
    orders: Orders,
    setOrders: Dispatch<SetStateAction<Orders>>,
    setSubordersByStatus: Dispatch<SetStateAction<SubordersByStatus>>,
  ) =>
  () => {
    const tokens: string[] = []
    if (merchant) {
      tokens.push(
        ...orderClient.subscribeOrders(
          merchant,
          refCallback(
            merchant,
            refs,
            setRefs,
            orders,
            setOrders,
            setSubordersByStatus,
          ),
        ),
      )
    }

    return () => {
      orderClient.unsubscribe([
        ...tokens,
        ...(Object.values(map).flat() as string[]),
      ])
    }
  }
