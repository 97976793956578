import { ItemPrice, Order, OrderInput, PriceService } from '@lib/brz-core-lib-type-ts/price'
import { Session } from '../utils/Session'
import { ApiSpec, HttpClient, Logger } from '../utils'

export class PriceClient extends HttpClient implements PriceService {
  constructor({ priceApi, session, logger }: { session?: Session; logger: Logger; priceApi: string }) {
    super(priceApi, session, logger)
  }
  async calculateOrderPrice(order: OrderInput): Promise<Order> {
    try {
      const response = await this.instance.post<ApiSpec<Order>>('order', order)
      return Promise.resolve(response.data.value)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async calculateItemPrice(): Promise<ItemPrice> {
    return {} as Promise<ItemPrice>
  }
}
