import { useEffect, useState, Fragment } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  VStack,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tooltip,
  Select,
  Link,
  Text,
} from '@chakra-ui/react'
import { OrderClient } from '@lib/brz-core-lib-sdk-ts/order'
import {
  EventCallback,
  Order,
  OrderCommand,
  RefCallback,
} from '@lib/brz-core-lib-type-ts/order'
import { Location, Party, PartyType } from '@lib/brz-core-lib-type-ts/party'
import { PartyClient } from '@lib/brz-core-lib-sdk-ts/party'

import { DatabaseImpl } from '../../../firebase/modular'
import { SessionImpl } from '../../../firebase/compat'
import config from '../../../config'
import { Layout } from 'src/ui/Layout'
import { Id } from '@lib/brz-core-lib-type-ts/utils/primitives'
import {
  GeoReferenceClient,
  ProductClient,
  UserClient,
} from '@lib/brz-core-lib-sdk-ts'
import {
  dateAgo,
  dateBetweenStr,
} from '@lib/brz-core-lib-type-ts/utils/dateAgo'
import {
  EnvType,
  getSuffix,
  first,
  getDate,
} from '@lib/brz-core-lib-type-ts/utils'
import { logger } from '@lib/brz-core-lib-sdk-ts/utils'
import { UserSession } from '@lib/brz-core-lib-type-ts/user'
import Queue from 'smart-request-balancer'
import { ReferenceResult } from '@lib/brz-core-lib-type-ts/georeference'

logger.setApp({ name: 'brz-partner-app-web-react' })
logger.addOptions({
  console: { threshold: 'error' },
  sentry: { threshold: 'error' },
})

const STATUS_VALUES = {
  pending: [
    'new',
    'abandoned',
    'checkout',
    'waitingPayment',
    'paymentConfirmed',
  ],
  inProgress: [
    'received',
    'waitingPreparation',
    'preparationComplete',
    'waitingPickup',
    'pickupComplete',
    'waitingDelivery',
  ],
  delivered: [
    'deliveryComplete',
    'serviceInProgress',
    'serviceComplete',
    'canceledByRunner',
    'canceledByPlatform',
    'canceledByMerchant',
  ],
}

const session = new SessionImpl()
const db = new DatabaseImpl(logger)

const orderClient = new OrderClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  productApi: config('PRODUCT_API'),
  partyApi: config('PARTY_API'),
})

const partyClient = new PartyClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  partyApi: config('PARTY_API'),
})

const productClient = new ProductClient({
  logger,
  session,
  productApi: config('PRODUCT_API'),
})

const userClient = new UserClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  userApi: config('USER_API'),
})

const geoClient = new GeoReferenceClient({
  logger,
  session,
  referenceApi: config('GEOREFERENCE_API'),
})

const WEEKDAY = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

const initialDate = new Date().toISOString().substring(0, 10)
const initialParty = 'all'
const initialStatus = ''

const queue = new Queue({
  rules: {
    guest: {
      rate: 30,
      limit: 10,
      priority: 10,
    },
    runner: {
      rate: 30,
      limit: 1,
      priority: 100,
    },
  },
  overall: {
    rate: 50,
    limit: 50,
    priority: 1,
  },
  default: {
    rule: 'runner',
    key: '',
  },
  retryTime: 300,
  ignoreOverallOverheat: true,
})

const runnerLocationMap: Record<string, Location> = {}
const guestLocationMap: Record<string, Location> = {}
const addressLocationMap: Record<string, Location> = {}

const addLocation = (
  id: Id<'order'>,
  location: Location,
  map: Record<string, Location>,
) => {
  const found = map[id]
  if (
    found &&
    found?.latitude === location?.latitude &&
    found?.longitude === location?.longitude
  ) {
    return found
  } else {
    map[id] = location
    return found
  }
}

const getAddress = ({ latitude, longitude }: Location) => {
  const key = `${latitude},${longitude}`
  return addressLocationMap[key]
}

const getReference = (references: ReferenceResult[]) => {
  const subzones = references.filter(item => item.type === 'subzone')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return first(subzones) || first(references)
}

const updateLocation =
  (
    id: Id<'order'>,
    partyType: PartyType,
    map: Record<string, Location>,
    setOrders: (
      value: React.SetStateAction<Record<Id<'order'>, Order>>,
    ) => void,
  ) =>
  async () => {
    const location = map[id]
    const { latitude, longitude } = location ?? {}
    const key = `${latitude},${longitude}`
    let address = addressLocationMap[key]?.address

    if (!address) {
      try {
        const references = await geoClient.getStaticReferencesByLatLong(
          latitude,
          longitude,
          0,
          0,
        )
        address = getReference(references)?.name || 'not found'
        addressLocationMap[key] = { ...location, address }
      } catch (e) {
        // console.log(count, '&>', id, e)
      }
    }

    setOrders(orders => {
      const order = orders[id]

      if (partyType === 'guest' && order?.guest?.location) {
        order.guest.location.address = address
        // console.log(count, '<<guest', id, address)
      } else if (partyType === 'runner' && order?.runner?.location) {
        // console.log(count, '<<runner', id, address)
        order.runner.location.address = address
      }

      return { ...orders }
    })
  }

const setLocation = (
  order: Order,
  partyType: PartyType,
  map: Record<string, Location>,
  setOrders: (value: React.SetStateAction<Record<Id<'order'>, Order>>) => void,
) => {
  const id = order.id

  const party =
    partyType === 'guest'
      ? order?.guest
      : partyType === 'runner'
      ? order?.runner
      : undefined

  const location =
    partyType === 'guest'
      ? order?.location
      : partyType === 'runner'
      ? party?.location
      : undefined

  if (location && location?.latitude && location?.longitude) {
    const address = getAddress(location)?.address
    if (address) {
      location.address = address
    } else {
      location.address = undefined
      const found = addLocation(id, location, map)
      if (!found) {
        void queue.request(
          updateLocation(id, partyType, map, setOrders),
          party.id,
          partyType,
        )
      }
    }
  }
}

const tokensMap = new Map<string, string[]>()

const partyProcessed = new Set<Id<'party'>>()
const orderProcessed: Record<Id<'order'>, Order> = {}
const dates = [] as string[]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AdminOrdersScreen = (props: RouteComponentProps) => {
  const [date, setDate] = useState(initialDate)
  const [party, setParty] = useState(initialParty)
  const [status, setStatus] = useState(initialStatus)

  const [sessions, setSessions] = useState<Record<Id<'party'>, UserSession>>({})

  const [parties, setParties] = useState<Party[]>()

  const [orders, setOrders] = useState<Record<Id<'order'>, Order>>({})

  const [ordersByDate, setOrdersByDate] = useState<
    Record<string, Id<'order'>[]>
  >({})
  const [partiesByOrder, setPartiesByOrder] = useState<
    Record<Id<'order'>, Set<Id<'party'>>>
  >({})

  useEffect(() => {
    const init = async () => {
      const parties = await partyClient.findPartyByType()
      setParties(parties)

      const result = (await userClient.getSessions()).filter(
        session => session.phone,
      )
      result.sort((party1, party2) =>
        party1.metadata?.lastSignInTime.localeCompare(
          party2.metadata?.lastSignInTime,
        ),
      )

      setSessions(
        Object.assign(
          {},
          ...result.map(session => ({ [session.partyId]: session })),
        ) as Record<Id<'party'>, UserSession>,
      )
    }

    init().catch(console.error)
  }, [])

  useEffect(() => {
    const list = !party
      ? []
      : party === 'all'
      ? [
          'party__mock__piola',
          'party__mock__beachrunnerz',
          'party__mock__street-dinner',
          'party__mock__naked-taco',
          'party__mock__cuba-cafe',
        ]
      : [party]

    console.log(list)

    list.forEach(party => {
      const eventCallback: EventCallback = (state, events) => {
        const order = state.order
        const id = order?.id
        orderProcessed[id] = order

        const orderSaved =
          events.findIndex(event => event.type === 'orderSaved') >= 0

        const date = getDate(id)
        const list = ordersByDate[date]

        list.sort((orderId1, orderId2) => {
          const orderCreated1 =
            (orderId1 === id
              ? order.timeline?.orderCreated
              : orderProcessed[orderId1]?.timeline?.orderCreated) ?? 'X'
          const orderCreated2 =
            (orderId2 === id
              ? order.timeline?.orderCreated
              : orderProcessed[orderId2]?.timeline?.orderCreated) ?? 'X'

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return orderCreated2.localeCompare(orderCreated1)
        })
        setOrdersByDate({ ...ordersByDate })

        setLocation(order, 'guest', guestLocationMap, setOrders)
        setLocation(order, 'runner', runnerLocationMap, setOrders)

        setOrders(orders => {
          orders[id] = state.order

          return { ...orders }
        })
        orderProcessed[id] = order
      }

      const refCallback: RefCallback = ref => {
        if (!orderProcessed[ref.id]) {
          orderProcessed[ref.id] = ref as Order
          orderClient.subscribeOrder(party, ref.id, eventCallback)

          const date = getDate(ref.id)
          let list = ordersByDate[date]
          if (!list) {
            list = []
            ordersByDate[date] = list
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            dates.push(date)
            dates.sort().reverse()
          }
          list.push(ref.id)
          setOrdersByDate({ ...ordersByDate })
        }
        let list = partiesByOrder[ref.id]
        if (!list) {
          list = new Set()
          partiesByOrder[ref.id] = list
        }

        list.add(party)
        setPartiesByOrder({ ...partiesByOrder })
      }

      if (!partyProcessed.has(party)) {
        partyProcessed.add(party)
        orderClient.subscribeOrders(party, refCallback)
      }
    })

    return () => {
      // orderClient.unsubscribe([
      //   ...tokens,
      //   ...(Object.values(map).flat() as string[]),
      // ])
    }
  }, [party])

  // console.log(Object.keys(refs))

  const firstDate = new Date(date || initialDate)
  return (
    <Layout>
      <VStack mt={8} spacing={8} align="flex-start">
        <HStack spacing={4}>
          <Tooltip label="Date">
            <Select
              value={date}
              onChange={event => setDate(event.target.value.trim())}>
              <>
                <option value="">All dates</option>
                <option value={initialDate}>{initialDate}</option>
                {Array(14)
                  .fill(0)
                  .map((_, index) => {
                    const current = new Date(firstDate)
                    current.setDate(current.getDate() - index)
                    const value = current.toISOString().substring(0, 10)

                    return initialDate === value ? (
                      <Fragment key={value}></Fragment>
                    ) : (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    )
                  })}
              </>
            </Select>
          </Tooltip>
          <Tooltip label="Party Id">
            <Select
              value={party}
              onChange={event => setParty(event.target.value.trim())}>
              <>
                <option value="all">All merchants</option>
                {parties?.map(party => (
                  <option value={party.id} key={party.id}>
                    {party.name} ({party.type})
                  </option>
                ))}
              </>
            </Select>
          </Tooltip>
          <Tooltip label="Status">
            <Select
              value={status}
              onChange={event => setStatus(event.target.value.trim())}>
              <option value="">All statuses</option>
              <option value="pending">Pending</option>
              <option value="inProgress">In progress</option>
              <option value="delivered">Delivered</option>
            </Select>
          </Tooltip>
          {/* <IconButton
            aria-label="Save Order"
            title="Save Order"
            icon={<MdAdd />}
            onClick={() => {
              dispatchSaveOrder(
                undefined,
                guest,
                orderClient,
                partyClient,
                productClient,
              ).catch(console.error)
            }}
          /> */}
        </HStack>

        <TableContainer>
          <Table padding={0}>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>ID</Th>
                <Th>Status</Th>
                <Th>Delivery Instructions</Th>
                <Th>Guest</Th>
                <Th>Runner</Th>
                <Th>Merchant</Th>
                <Th>Timeline</Th>
                <Th>Version</Th>
                <Th>Items</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dates.map(group => {
                const rows = ordersByDate[group] ?? []
                return !(!date || date === group) ? (
                  <Fragment key={group} />
                ) : (
                  <Fragment key={group}>
                    <Tr fontWeight="bold" bg="gray.50">
                      <Td verticalAlign="top" paddingY={1} colSpan={9}>
                        {group}
                        <Text as="span" fontSize="xs" fontWeight="normal">
                          {' '}
                          ({rows.length} order
                          {rows.length > 1 ? 's' : ''})
                        </Text>
                      </Td>
                      <Td verticalAlign="top" paddingY={1}></Td>
                    </Tr>
                    {rows.map((id, index) => {
                      const order = orders[id]
                      const suborders = order?.suborders

                      if (
                        (party &&
                          party !== 'all' &&
                          partiesByOrder[id] &&
                          !partiesByOrder[id].has(party)) ||
                        (status &&
                          !STATUS_VALUES[status]?.includes(order?.status))
                      ) {
                        return <Fragment key={index}></Fragment>
                      }

                      return (
                        <Tr key={index}>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            title={JSON.stringify(order, undefined, 2)}>
                            {index + 1}
                          </Td>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            fontSize="xs"
                            title={id}>
                            #{getSuffix(id)}
                          </Td>
                          <Td verticalAlign="top" paddingY={1} fontSize="xs">
                            {order?.status}
                          </Td>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            fontSize="xs"
                            minW={200}
                            maxW={300}
                            wordBreak="break-word"
                            whiteSpace="normal">
                            <strong>
                              {order?.guest?.name && `${order?.guest.name}`}
                            </strong>
                            <br />
                            {order?.deliveryInstructions &&
                              `${order?.deliveryInstructions}\n`}
                          </Td>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            fontSize="xs"
                            title={order?.guest?.id}>
                            {order?.guest && (
                              <>
                                {order?.guest?.id && sessions[order?.guest.id] && (
                                  <>
                                    {sessions[order?.guest.id]?.phone}
                                    <br />
                                  </>
                                )}
                                {order?.guest?.location && (
                                  <a
                                    target="_blank"
                                    href={`http://maps.google.com/maps?t=k&q=loc:${order?.guest?.location?.latitude},${order?.guest?.location?.longitude}`}>
                                    {order?.guest?.location?.address}
                                  </a>
                                )}
                              </>
                            )}
                          </Td>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            fontSize="xs"
                            title={order?.runner?.id}>
                            {order?.runner && (
                              <>
                                {getSuffix(order?.runner?.id)}
                                <br />
                                {order?.runner?.id &&
                                  sessions[order?.runner.id] && (
                                    <>
                                      {sessions[order?.runner.id].phone}
                                      <br />
                                    </>
                                  )}
                                {order?.runner?.location && (
                                  <a
                                    target="_blank"
                                    href={`http://maps.google.com/maps?t=k&q=loc:${order?.runner?.location?.latitude},${order?.runner?.location?.longitude}`}>
                                    {order?.runner?.location?.address}
                                  </a>
                                )}
                              </>
                            )}
                          </Td>
                          <Td verticalAlign="top" paddingY={1} fontSize="xs">
                            {order?.suborders?.map((suborder, index) => (
                              <Fragment key={index}>
                                <Link
                                  href={`/merchants/${suborder.merchant.id}/orders`}>
                                  {suborder.merchant.name}
                                </Link>
                                <br />
                              </Fragment>
                            ))}
                          </Td>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            fontSize="xs"
                            lineHeight="short">
                            {order?.timeline?.orderCreated
                              ?.replace(/[TZ]/g, ' ')
                              .trim()}
                            {order?.timeline?.orderSubmited ? (
                              <>
                                <br />
                                submited:{' '}
                                {dateBetweenStr(
                                  order?.timeline?.orderCreated,
                                  order?.timeline?.orderSubmited,
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {order?.timeline?.orderConfirmed ? (
                              <>
                                <br />
                                confirmed:{' '}
                                {dateBetweenStr(
                                  order?.timeline?.orderCreated,
                                  order?.timeline?.orderConfirmed,
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {order?.timeline?.orderPrepared ? (
                              <>
                                <br />
                                prepared:{' '}
                                {dateBetweenStr(
                                  order?.timeline?.orderCreated,
                                  order?.timeline?.orderPrepared,
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {order?.timeline?.orderPickedup ? (
                              <>
                                <br />
                                pickedup:{' '}
                                {dateBetweenStr(
                                  order?.timeline?.orderCreated,
                                  order?.timeline?.orderPickedup,
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {order?.timeline?.orderDelivered ? (
                              <>
                                <br />
                                delivered:{' '}
                                {dateBetweenStr(
                                  order?.timeline?.orderCreated,
                                  order?.timeline?.orderDelivered,
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Td>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            fontSize="xs"
                            title={
                              order?.version &&
                              new Date(order?.version).toISOString()
                            }>
                            {dateAgo(new Date(order?.version))}
                          </Td>
                          <Td
                            verticalAlign="top"
                            paddingY={1}
                            fontSize="xs"
                            minW={200}
                            maxW={300}
                            wordBreak="break-word"
                            whiteSpace="normal">
                            {suborders?.map(suborder => (
                              <Fragment key={suborder.id}>
                                <strong>{suborder.merchant.name}</strong>
                                <br />
                                {suborder.items?.map(item => (
                                  <Fragment key={item.id}>
                                    • {item.name}
                                    {item.description && (
                                      <Text as="span">
                                        {' => '} {item.description}
                                      </Text>
                                    )}
                                    <br />
                                    {/* {item.description.split('\n').map(line =>
                                      line
                                        .split(/[,\\:] /g)
                                        .map((value, index) => (
                                          <>
                                            {index ? '• ' : ''}
                                            {value}
                                            <br />
                                          </>
                                          // <Text
                                          //   key={index}
                                          //   pl={index ? 4 : 2}
                                          //   lineHeight="none"
                                          //   fontSize={index ? 'sm' : 'md'}>
                                          //   {index ? '• ' : ''}
                                          //   {item}
                                          // </Text>
                                        )),
                                    )} */}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                          </Td>
                          {/*<Td  verticalAlign="top" paddingY={1}>
                            <HStack spacing={4}>
                               <IconButton
                                variant="ghost"
                                aria-label="Save Order"
                                title="Save Order"
                                icon={<MdAdd />}
                                onClick={() => {
                                  dispatchSaveOrder(
                                    id,
                                    guest,
                                    orderClient,
                                    partyClient,
                                    productClient,
                                  ).catch(console.error)
                                }}
                              />
                              <IconButton
                                variant="ghost"
                                aria-label="Checkout Without Changes"
                                title="Checkout Without Changes"
                                icon={<MdShoppingBag />}
                                onClick={() => {
                                  orderClient
                                    .dispatch(id, [
                                      CHECKOUT_ORDER1(id, party_, ++count),
                                    ])
                                    .catch(error => console.error(error))
                                }}
                              />
                              <IconButton
                                variant="ghost"
                                aria-label="Submit Order"
                                title="Submit Order"
                                icon={<MdSend />}
                                onClick={() => {
                                  orderClient
                                    .dispatch(id, [SUBMIT_ORDER(id, party_)])
                                    .catch(error => console.error(error))
                                }}
                              />
                              <IconButton
                                variant="ghost"
                                aria-label="Update Guest Location"
                                title="Update Guest Location"
                                icon={<MdMyLocation />}
                                onClick={() => {
                                  orderClient
                                    .dispatch(id, [
                                      UPDATE_GUEST_LOCATION(
                                        id,
                                        party_,
                                        ++count,
                                      ),
                                    ])
                                    .catch(error => console.error(error))
                                }}
                              />
                              <IconButton
                                variant="ghost"
                                aria-label="Assign Runner Location"
                                title="Assign Runner Location"
                                icon={<MdPersonAdd />}
                                onClick={() => {
                                  orderClient
                                    .dispatch(id, [
                                      ASSIGN_RUNNER_COMMAND(
                                        id,
                                        runner,
                                        ++count,
                                      ),
                                    ])
                                    .catch(error => console.error(error))
                                }}
                              />
                              <IconButton
                                variant="ghost"
                                aria-label="Complete Pickup"
                                title="Complete Pickup"
                                icon={<MdPlayArrow />}
                                onClick={() => {
                                  orderClient
                                    .dispatch(id, [
                                      COMPLETE_PICKUP_COMMAND(
                                        id,
                                        party_,
                                        ++count,
                                      ),
                                    ])
                                    .catch(error => console.error(error))
                                }}
                              />
                              <IconButton
                                variant="ghost"
                                aria-label="Complete Delivery"
                                title="Complete Delivery"
                                icon={<MdDone />}
                                onClick={() => {
                                  orderClient
                                    .dispatch(id, [
                                      COMPLETE_DELIVERY_COMMAND(
                                        id,
                                        party_,
                                        ++count,
                                      ),
                                    ])
                                    .catch(error => console.error(error))
                                }}
                              /> 
                            </HStack>
                          </Td>*/}
                        </Tr>
                      )
                    })}
                  </Fragment>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Layout>
  )
}

type mockFn = (id: string, party: string, version?: number) => OrderCommand

const CHECKOUT_ORDER1: mockFn = (id, party, version) => ({
  type: 'checkoutOrder',
  payload: {
    order: {
      id,
      location: {
        latitude: 25.76923528587253 + version / 100,
        longitude: -80.13046056049492 + version / 100,
        address: '1st St. LGP, Zone 1, South Beach',
        heading: 90 + version,
      },
    },
  },
})

const SUBMIT_ORDER: mockFn = id => ({
  type: 'submitOrder',
  payload: {
    order: {
      id: id,
    },
  },
})

const UPDATE_GUEST_LOCATION: mockFn = (id, _, version) => ({
  type: 'updateGuestLocation',
  payload: {
    order: {
      id: id,
      guest: {
        name: '(Guest Name)',
        location: {
          latitude: 25.76923528587253 + version / 100,
          longitude: -80.13046056049492 + version / 100,
          address: '1st St. LGP, Zone 1, South Beach',
          heading: 90 + version,
        },
      },
    },
  },
})

const ASSIGN_RUNNER_COMMAND: mockFn = (id, party) => ({
  type: 'assignRunner',
  payload: {
    order: {
      id,
      runner: {
        id: party,
        location: {
          address: '1st St. LGP, Zone 1, South Beach',
          heading: 90,
          latitude: 25.81294479735087,
          longitude: -80.12181052639853,
        },
      },
    },
  },
})

const COMPLETE_PICKUP_COMMAND: mockFn = id => ({
  type: 'completePickup',
  payload: {
    order: {
      id,
      suborder: `suborder-${id}-1`,
    },
  },
})

const COMPLETE_DELIVERY_COMMAND: mockFn = id => ({
  type: 'completeDelivery',
  payload: {
    order: {
      id,
    },
  },
})
