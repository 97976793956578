import { Id } from './primitives'
import { customAlphabet } from 'nanoid'

const alphabet = '346789ABCDEFGHJKLMNPQRTUVWXY'
const nolookalikesSafe = (size: number) => customAlphabet(alphabet, size)()

const date = (options?: Options) =>
  (options?.date === true
    ? new Date().toISOString().replace(/\D/g, '').substring(2, 12) + '_'
    : '') + (options?.id ? options.id + '_' : '')

export type Options = {
  id?: string
  date?: boolean
}

type Fn = (options?: Options) => string

const FN: Record<string, Fn> = {
  order: options => date({ ...options, date: true }) + nolookalikesSafe(4),
  suborder: options => date({ ...options, date: false }) + nolookalikesSafe(4),
  item: options => date({ ...options, date: false }) + nolookalikesSafe(4),
}
const def = (options?: Options) =>
  options?.id ?? date(options) + nolookalikesSafe(20)

const gen = (kind: string, options?: Options | string) => {
  const opt: Options | undefined =
    typeof options === 'string' ? { id: options } : options
  const fn = FN[kind]
  return (fn && fn(opt)) || def(opt)
}

const ID = <K extends string>(
  kind: K,
  namespace: string,
  options?: Options | string,
): Id<K> =>
  `${kind.toLowerCase()}__${namespace.toLowerCase()}__${gen(kind, options)}`

export const getSuffix = (id?: string) => (id ? id.replace(/.*_/g, '') : id)
export const getDate = (id?: string) => {
  if (!id) {
    return undefined
  }
  const text = id.replace(
    /(.*__)(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(.*)/g,
    '20$2-$3-$4',
  )
  return text
}

export default ID
