import React, { Fragment, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  HStack,
  Tooltip,
  Select,
  Code,
} from '@chakra-ui/react'
import { DatabaseImpl } from '../../../firebase/modular'
import { SessionImpl } from '../../../firebase/compat'
import config from '../../../config'
import { Layout } from 'src/ui/Layout'
import { EnvType, getSuffix } from '@lib/brz-core-lib-type-ts/utils'
import { logger } from '@lib/brz-core-lib-sdk-ts/utils'
import { UserClient } from '@lib/brz-core-lib-sdk-ts'
import { UserSession } from '@lib/brz-core-lib-type-ts/user'

logger.setApp({ name: 'brz-partner-app-web-react' })
logger.addOptions({
  console: { threshold: 'warn' },
  sentry: { threshold: 'warn' },
})

const session = new SessionImpl()
const db = new DatabaseImpl(logger)

const client = new UserClient({
  prefix: config('ENV') as EnvType,
  logger,
  session,
  db,
  userApi: config('USER_API'),
})

const WEEKDAY = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

const initialDate = new Date().toISOString().substring(0, 10)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AdminSessionsScreen = (props: RouteComponentProps) => {
  const [date, setDate] = useState(initialDate)
  const [map, setMap] = useState(new Map<string, UserSession[]>())

  useEffect(() => {
    const init = async () => {
      const result = (await client.getSessions()).map(session => {
        session.metadata = session.metadata || {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          creationTime: (session as any).creationTime,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          lastSignInTime: (session as any).lastSignInTime,
        }

        session.metadata.lastSignInTime =
          session.metadata.lastSignInTime.startsWith('2022')
            ? session.metadata.lastSignInTime
            : session.metadata.creationTime

        return session
      })
      result.sort((party1, party2) => {
        const lastSignInTime1: string = party1.metadata?.lastSignInTime
        const lastSignInTime2: string = party2.metadata?.lastSignInTime

        return lastSignInTime2?.localeCompare(lastSignInTime1)
      })

      const map = new Map<string, UserSession[]>()

      result.forEach(session => {
        const group = session.metadata?.lastSignInTime.substring(0, 10)

        let list = map.get(group)
        if (!list) {
          list = []
          map.set(group, list)
        }
        list.push(session)
      })

      setMap(map)
    }
    void init()

    return () => {}
  }, [client])

  const firstDate = new Date(date || initialDate)
  return (
    <Layout>
      <VStack mt={8} spacing={8} align="flex-start">
        <HStack spacing={4}>
          <Tooltip label="Date">
            <Select
              value={date}
              onChange={event => setDate(event.target.value.trim())}>
              <>
                <option></option>
                <option value={initialDate}>
                  {initialDate} ({map.get(initialDate)?.length || 0})
                </option>
                {Array(14)
                  .fill(0)
                  .map((_, index) => {
                    const current = new Date(firstDate)
                    current.setDate(current.getDate() - index)
                    const value = current.toISOString().substring(0, 10)

                    return initialDate === value ? (
                      <Fragment key={value}></Fragment>
                    ) : (
                      <option value={value} key={value}>
                        {value} ({map.get(value)?.length || 0})
                      </option>
                    )
                  })}
              </>
            </Select>
          </Tooltip>
        </HStack>

        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Last Signin</Th>
                <Th>Phone</Th>
                <Th>Type</Th>
                <Th>Party ID</Th>
                <Th>Sentry ID</Th>
                <Th>Device Type</Th>
                <Th>Device Token</Th>
                <Th>Creation</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.from(map.entries()).map(([group, sessions]) =>
                !(!date || date === group) ? (
                  <Fragment key={group} />
                ) : (
                  <Fragment key={group}>
                    <Tr fontWeight="bold" bg="gray.50">
                      <Td paddingY={1} colSpan={8}>
                        {group}
                        <Text as="span" fontSize="xs" fontWeight="normal">
                          {' '}
                          ({sessions.length} session
                          {sessions.length > 1 ? 's' : ''})
                        </Text>
                      </Td>
                      <Td paddingY={1}></Td>
                    </Tr>
                    {sessions.map((session, index) => {
                      return (
                        <Tr key={index}>
                          <Td paddingY={1}>{index + 1}</Td>
                          <Td paddingY={1} fontSize="xs">
                            {session.metadata?.lastSignInTime
                              ?.replace(/[TZ]/g, ' ')
                              .trim()}
                          </Td>
                          <Td paddingY={1} fontSize="xs">
                            {session.phone}
                          </Td>
                          <Td paddingY={1} fontSize="xs">
                            {session.partyType}
                          </Td>
                          <Td
                            paddingY={1}
                            fontSize="xs"
                            title={JSON.stringify(session, undefined, 2)}>
                            <Code
                              fontSize="xs"
                              lineHeight="short"
                              bg="transparent">
                              {session.partyId.includes('mock')
                                ? getSuffix(session.partyId)
                                : `${getSuffix(session.partyId).substring(
                                    0,
                                    4,
                                  )}...${getSuffix(session.partyId).slice(-4)}`}
                            </Code>
                          </Td>
                          <Td paddingY={1} fontSize="xs">
                            {session.sentrySessionIds}
                          </Td>
                          <Td paddingY={1} fontSize="xs">
                            {session.deviceType}
                          </Td>
                          <Td
                            paddingY={1}
                            fontSize="xs"
                            title={session.deviceToken}>
                            <Code
                              fontSize="xs"
                              lineHeight="short"
                              bg="transparent">
                              {session.deviceToken?.substring(0, 4)}...
                              {session.deviceToken?.slice(-4)}
                            </Code>
                          </Td>
                          <Td paddingY={1} fontSize="xs">
                            {session.metadata?.creationTime
                              ?.replace(/[TZ]/g, ' ')
                              .trim()}
                          </Td>
                        </Tr>
                      )
                    })}
                  </Fragment>
                ),
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Layout>
  )
}
